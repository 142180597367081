#sticky__account-menu-trigger {
    @include breakpoint(medium) {
        background-color: color(Dane, Red);
        color: color(Dane, White);
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 206.34 128.17' style='enable-background:new 0 0 206.34 128.17;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,25c0-6.4,2.44-12.8,7.32-17.68c9.76-9.76,25.59-9.76,35.36,0l60.49,60.49l60.49-60.49 c9.76-9.76,25.59-9.76,35.36,0c9.76,9.76,9.76,25.59,0,35.36l-78.17,78.17c-4.69,4.69-11.05,7.32-17.68,7.32s-12.99-2.63-17.68-7.32 L7.32,42.68C2.44,37.8,0,31.4,0,25z'/%3E%3C/svg%3E%0A");
        &:hover {
            background-color: darken(color(Dane, Red), 10%);
        }
    }
}

.open {
    #sticky__account-menu-trigger {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 206.34 128.17' style='enable-background:new 0 0 206.34 128.17;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M206.34,103.17c0,6.4-2.44,12.8-7.32,17.68c-9.76,9.76-25.59,9.76-35.36,0l-60.49-60.49l-60.49,60.49 c-9.76,9.76-25.59,9.76-35.36,0c-9.76-9.76-9.76-25.59,0-35.36L85.49,7.32C90.18,2.63,96.54,0,103.17,0s12.99,2.63,17.68,7.32 l78.17,78.17C203.9,90.37,206.34,96.77,206.34,103.17z'/%3E%3C/svg%3E%0A");
    }
}
