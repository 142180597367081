.catalog-category__toolbar {
    .size__select, .catalog-category__sorter {
        position: relative;
        &:after {
            content: '';
            display: block;
            width: 3rem;
            height: 100%;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{color(Dane, White)}" d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>');
            background-position: right .6rem center;
            background-repeat: no-repeat;
            background-size: 1rem;
            position: absolute;
            right: 0;
            top: 0;
            pointer-events: none;
        }

        label {
            display: none;
        }

        .show-for-between-medium-and-small {
            display: none;
        }

        select {
            border: .2rem solid color(Dane, Mesh);
            color: color(Dane, Mesh);
            font-weight: 700;
            line-height: 3.6rem;
            padding-right: 3.6rem;
            min-width: 0;
            box-shadow: inset -2rem 0px 0px 0px color(Dane, Mesh);
            position: relative;

        }
    }

    .mobile-filter {
        display: none;
    }

    .toolbar__top {
        .pagination {
            justify-content: flex-start;
            margin-left: 3rem;
        }
    }

    .toolbar__bottom {
        margin: 3rem 0;

        &.gear-up {
            ul {
                li {
                    ._gear_shift {
                        transform: rotate(0);
                    }
                }
            }
        }

        &.gear-down {
            ul {
                li {
                    ._gear_shift {
                        transform: rotate(-30deg);
                    }
                }
            }
        }

        ul {
            li {
                a {
                    background: color(base, White);
                    color: color(base, MediumGray);
                }

                ._gear_shift {
                    display: none;
                }

                .--current {
                    display: flex;
                    flex-direction: column;
                }
            }
        }
    }
}
