._category_description_content {
    position: relative;

    &.no-image {
        margin-bottom: 2rem;

        h1 {
            position: relative;
            margin: 0 0 2rem;
            display: inline-block;
        }
    }

    h1 {
        background: color(base, White);
        margin: 0 0 2rem;
    }

    @include breakpoint(small only) {
        .category__text {
            max-height: 17rem;
            overflow: hidden;
            margin-bottom: 1.6rem;
            &.--active{
                height: auto;
                overflow: visible;
                margin-bottom: 0;
                &:before{
                    content:none;
                }
            }
            &:before{
                background: linear-gradient(0deg, #fff, hsla(0, 0%, 100%, 0));
                content: "";
                height: 15rem;
                left: 0;
                position: absolute;
                bottom: 0;
                width: 100%;
            }
        }
        .--show-more{
            font-size:1.6rem;
            color: color(base, revBlue);
            position:relative;
            z-index: 1;
            display: block;
            margin: 0 auto 1.6rem;
        }
    }

    .image_container {
        position: relative;
        margin-bottom: 2.4rem;
        overflow: hidden;

        &::before {
            content: '';
            padding-top: 33%;
            display: block;
            @include breakpoint(small only) {
                padding-top: 60%;
            }
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 0;
            top: 0;
        }
    }

    p {
        font-size: 1.6rem;
        margin-bottom: 2.4rem;
    }
}

.catalog__category-list {
    position: relative;

    .--loading {
        @include absolute-loading('light');
        align-items: flex-start;
        z-index: 2;

        > span {
            margin: 10%;
        }
    }

    @include breakpoint(medium) {
        > ul {
            border-bottom: .1rem solid color(gray, Gray-200);
            margin-bottom: 3rem;
        }
    }

    .product__tile {
        @include transition;
        padding-bottom: 2rem;
        height: calc(100% - 3rem);
        display: flex;
        flex-flow: column;
        margin-bottom: 3rem;
        border-bottom: .2rem solid color(base, DefaultFontColor);

        @include breakpoint(medium) {
            border-right: .1rem solid color(gray, Gray-200);
            border-bottom: none;
        }

        .product__swatches {
            display: flex;
            align-items: center;
            flex: 0 0 7.3rem;
            padding: 0 .5rem;

            @include breakpoint(medium) {
                padding: 0 2rem;
            }

            .swiper {
                flex: 1;
                max-width: 100%;

                .swiper-wrapper {
                    @include breakpoint(medium) {
                        &.--center {
                            justify-content: center;
                        }
                    }

                    .swiper-slide {
                        display: flex;
                        justify-content: center;

                        > span {
                            display: block;
                            aspect-ratio: 1;
                            cursor: pointer;
                            border-bottom: .3rem solid transparent;

                            @include hover() {
                                border-bottom-color: color(base, DefaultFontColor);
                            }
                        }
                    }
                }
            }
        }

        > a {
            display: flex;
            flex-flow: column;
            flex: 1;

            .image__holder {
                display: block;
                position: relative;
                aspect-ratio: 1;
                margin: 1rem;

                @include breakpoint(medium) {
                    margin: 2rem;
                }

                img {
                    @include transition;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }

            .product__holder {
                display: flex;
                flex-flow: column;
                padding: 0 2rem;
                text-align: center;
                height: 100%;

                .--brand {
                    color: color(gray, Gray-500);
                    font-size: 1.4rem;
                }

                h3 {
                    @include transition;
                    font-weight: 700;
                    text-transform: initial;
                    color: color(base, DefaultFontColor);
                    flex: 1;
                    font-size: 1.4rem;

                    @include breakpoint(medium) {
                        font-size: 1.6rem;
                    }
                }

                ul {
                    margin: 1rem 0;

                    li {
                        color: color(base, DefaultFontColor);
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        gap: .75rem;
                        font-size: 1rem;
                        line-height: 1.4rem;

                        @include breakpoint(medium) {
                            font-size: 1.4rem;
                            line-height: 2.4rem;
                        }

                        &::before {
                            content: '';
                            display: block;
                            height: .8rem;
                            flex: 0 0 1rem;
                            background-image: url("data:image/svg+xml,%3Csvg class='w-6 h-6 text-gray-800 dark:text-white' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 12'%3E%3Cpath stroke='#{color(base, Green)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M1 5.917 5.724 10.5 15 1.5'/%3E%3C/svg%3E");
                            background-repeat: no-repeat;
                        }
                    }
                }

                .product__label {
                    height: 1.8rem;
                    display: block;
                    margin: 0 0 2rem;
                }

                .price__holder {
                    .price {
                        font-size: 1.6rem;
                        color: color(base, DefaultFontColor);
                        font-weight: 700;
                    }
                }
            }

            @include hover() {
                .image__holder {
                    img {
                        transform: scale(.9);
                    }
                }
                .product__holder {
                    h3 {
                        color: color(base, PrimaryButton);
                    }
                }
            }
        }

        .view__details {
            text-align: center;
            margin: 1.5rem 0 0;
        }
    }

    .category__placeholder {
        .--placeholder {
            display: block;
            aspect-ratio: 62/103;
            margin: 0 0 5rem;
            border-right: .1rem solid color(gray, Gray-200);
            padding: 0 2rem;

            > span {
                display: block;
                width: 100%;
                height: 100%;
                background-color: #F9F9F9;
            }
        }

        .--placeholder-line {
            display: block;
            width: 100%;
            height: .1rem;
            background-color: color(Gray, Gray-200);
            margin-bottom: 3rem;
        }
    }
}

.catalog__category-slider {
    > .swiper__button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        z-index: 3;
        left: -3.5rem;

        &.--next {
            left: auto;
            right: -3.5rem;
        }
    }
}

.catalog-category-page_body {
    .catalog__category-list {
        @include breakpoint(medium) {
            > ul {
                li:nth-child(3n) {
                    .product__tile {
                        border: none;
                    }
                }
            }
        }
    }
}

.homepage__product__container {
    .catalog__category-list {
        @include breakpoint(medium) {
            > ul {
                li:nth-child(4n) {
                    .product__tile {
                        border: none;
                    }
                }
            }
        }
    }
}
