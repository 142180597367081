.customer-account {
    margin-top: 3rem;

    .customer__account-navigation_toggle {
        margin: 0 0 2rem;
    }

    .customer__account-navigation {
        nav {
            margin: 2rem 0;

            ul {
                margin-bottom: 2rem;
            }

            ul li {
                padding: .5rem 0;

                a {
                    align-items: center;
                    border-left: .3rem solid transparent;
                    display: flex;
                    font-size: 1.5rem;
                    gap: 1rem;
                    line-height: 2.8rem;
                    padding: 0 1rem;
                    color: color(base, DefaultFontColor);

                    span {
                        display: block;
                        height: 2.2rem;
                        width: 2.2rem;

                        &.--overview {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z' /%3E%3C/svg%3E%0A");
                        }

                        &.--settings {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z' /%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E%0A");
                        }

                        &.--addresses {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z' /%3E%3C/svg%3E%0A");
                        }

                        &.--orders,
                        &.--quick-buy {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z' /%3E%3C/svg%3E%0A");
                        }

                        &.--newsletter {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75' /%3E%3C/svg%3E%0A");
                        }

                        &.--logout {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75' /%3E%3C/svg%3E%0A");
                        }

                        &.--download {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3' /%3E%3C/svg%3E%0A");
                        }

                        &.--service {
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#{color(base, Primary)}" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M11.42 15.17L17.25 21A2.652 2.652 0 0021 17.25l-5.877-5.877M11.42 15.17l2.496-3.03c.317-.384.74-.626 1.208-.766M11.42 15.17l-4.655 5.653a2.548 2.548 0 11-3.586-3.586l6.837-5.63m5.108-.233c.55-.164 1.163-.188 1.743-.14a4.5 4.5 0 004.486-6.336l-3.276 3.277a3.004 3.004 0 01-2.25-2.25l3.276-3.276a4.5 4.5 0 00-6.336 4.486c.091 1.076-.071 2.264-.904 2.95l-.102.085m-1.745 1.437L5.909 7.5H4.5L2.25 3.75l1.5-1.5L7.5 4.5v1.409l4.26 4.26m-1.745 1.437l1.745-1.437m6.615 8.206L15.75 15.75M4.867 19.125h.008v.008h-.008v-.008z" /></svg>');
                        }

                        &.--reporting,
                        &.--invoice {
                            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="#{color(base, Primary)}" class="w-6 h-6"><path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" /></svg>');
                        }

                        &.--showroom {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z' /%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15 12a3 3 0 11-6 0 3 3 0 016 0z' /%3E%3C/svg%3E%0A");
                        }

                        &.--preorder {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, Primary)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z' /%3E%3C/svg%3E%0A");
                        }
                    }

                    @include hover {
                        text-decoration: underline;
                    }
                }

                &.--active {
                    a {
                        border-color: color(base, Primary);
                        font-weight: 700;
                    }
                }
            }
        }
    }

    .title__header {
        align-items: center;
        display: flex;
        flex-wrap: wrap;

        > a {
            display: inline-flex;
            font-size: 1.5rem;
            font-weight: 500;
            margin: 1rem 0 0;

            @include breakpoint(medium) {
                margin: 0 0 0 auto;
            }

            @include hover {
                text-decoration: underline;
            }
        }
    }

    h1 {
        font-size: 2.8rem;
        font-weight: 700;

        &.--center {
            text-align: center;
        }
    }

    h2 {
        font-weight: 600;

        &.--center {
            text-align: center;
        }
    }

    h3 {
        font-size: 1.8rem;
        font-weight: 500;

        &.--center {
            text-align: center;
        }

        &.--margin-bottom {
            margin-bottom: 2rem;
        }
    }

    form {
        > h3 {
            margin: 2rem 0;
        }
    }

    .--intro-block {
        @include border-radius;
        background-color: color(gray, Gray-100);
        margin: 0 0 3rem;
        padding: 2rem 3rem;

        @include breakpoint(medium) {
            background-position: right 2rem center;
            background-repeat: no-repeat;
            background-size: 15%;
            width: 100%;
            padding: 2rem 15rem 2rem 3rem;

            p {
                max-width: 60%;
            }
        }

        h1 {
            font-size: 2.4rem;
            font-weight: 700;
            text-transform: uppercase;

            @include breakpoint(medium) {
                font-size: 3.2rem;
            }
        }

        p {
            font-size: 1.4rem;
            line-height: 2.4rem;
            margin: 2rem 0 0;
            opacity: .8;

            @include breakpoint(medium) {
                font-size: 1.6rem;
            }
        }
    }

    .last__order, .order__row {
        border-bottom: .1rem solid color(gray, Gray-200);
        margin: 0 0 3rem;
        padding: 0 0 3rem;

        .--header {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
            gap: 1rem;

            span, small {
                color: color(base, Primary);
                font-size: 1.4rem;
                font-weight: 500;
            }
        }

        .item__list {
            display: grid;
            gap: 1rem;
            grid-template-columns: repeat(2, 1fr);
            margin: 1.5rem 0 0;

            @include breakpoint(medium) {
                grid-template-columns: repeat(6, 1fr);
            }

            a {
                @include transition;
                @include border-radius;
                box-shadow: rgba(9, 30, 66, 0.25) 0 4px 8px -2px, rgba(9, 30, 66, 0.08) 0 0 0 1px;
                display: flex;
                flex-direction: column;
                padding: 1rem 1.5rem;

                @include hover {
                    background-color: color(gray, Gray-100);
                }

                img {
                    aspect-ratio: 89/61;
                    height: 100%;
                    object-fit: scale-down;
                    width: 100%;
                }

                h3 {
                    font-size: 1.7rem;
                    font-weight: 600;
                    margin: 1rem 0 0;
                }

                p:last-child {
                    font-weight: 500;
                    margin: 1rem 0 0;

                    &.--default {
                        color: color(alert, Default);
                    }

                    &.--error {
                        color: color(alert, Error);
                    }

                    &.--success {
                        color: color(alert, Success);
                    }
                }
            }
        }

        > a {
            display: inline-flex;
            font-size: 1.5rem;
            font-weight: 500;
            margin: 2rem 0 0;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    .recently-viewed__products {
        border-bottom: .1rem solid color(gray, Gray-200);
        margin: 0 0 3rem;

        .--header {
            align-items: center;
            display: flex;
            gap: 2rem;
            margin: 0 0 1.5rem;

            .blazer-controls {
                margin-left: auto;
            }
        }


        .catalog__category-list .product__tile {
            margin-bottom: 1.5rem;
        }
    }

    .personal__details {
        h2 {
            margin: 0 0 1.5rem;
        }

        .personal__details-container {
            @include border-radius;
            border: .1rem solid color(gray, Gray-200);
            margin: 0 0 2rem;
            padding: 1rem 2rem;
            width: 100%;

            @include breakpoint(medium) {
                margin: 0;
                min-width: 65%;
                width: fit-content;
            }

            .--content {
                &:not(:last-child) {
                    margin: 0 0 1rem;
                }

                a {
                    @include hover {
                        text-decoration: underline;
                    }
                }
            }
        }
    }

    .customer__account_block {
        margin: 2rem 0;

        &.--background {
            @include border-radius;
            background: color(gray, Gray-100);
            padding: 2rem;
        }
    }

    .dashboard__addresses {
        h2 {
            margin: 0 0 1.5rem;
        }

        .dashboard__addresses-container {
            display: grid;
            gap: 1.5rem;
            grid-template-columns: repeat(2, 1fr);
        }

        > a {
            display: inline-flex;
            font-size: 1.5rem;
            font-weight: 500;
            margin: 1rem 0 0;

            @include hover {
                text-decoration: underline;
            }
        }
    }

    .address-container {
        display: grid;
        gap: 1.5rem;
        grid-template-columns: repeat(2, 1fr);
        margin: 2rem 0;

        @include breakpoint(medium) {
            grid-template-columns: repeat(5, 1fr);
        }
    }

    .address__block {
        @include border-radius;
        border: .1rem solid color(gray, Gray-200);
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1rem;

        @include breakpoint(medium) {
            padding: 1.5rem 2rem;
        }

        > a {
            align-items: center;
            display: flex;
            flex-direction: column;
            font-size: 1.5rem;
            font-weight: 500;
            gap: 1rem;
            justify-content: center;
            text-align: center;

            svg {
                width: 35%;
            }

            @include hover {
                color: color(base, PrimaryHover);
                text-decoration: underline;

                svg {
                    stroke: color(base, PrimaryHover);
                }
            }
        }

        h3 {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(alert, Success)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
            background-position: left center;
            background-repeat: no-repeat;
            background-size: 1.6rem;
            color: color(alert, Success);
            font-size: 1.4rem;
            font-weight: 300;
            margin: 0 0 1rem;
            padding-left: 2.5rem;
        }

        p {
            font-size: 1.5rem;
            font-weight: 600;
            margin: 0 0 1rem;

            @include breakpoint(medium) {
                font-size: 1.9rem;
            }
        }

        address {
            color: color(gray, Gray-700);
            font-size: 1.5rem;
            line-height: 2.5rem;
        }

        .--actions {
            display: flex;
            gap: 1rem;
            margin: 2rem 0 0;

            a {
                align-items: center;
                display: flex;
                justify-content: center;
                @include breakpoint(small only) {
                    flex: 1;
                    text-indent: -999rem;
                }

                @include breakpoint(medium) {
                    gap: 1rem;

                    &:last-child {
                        flex: 1;
                    }

                    &:first-child {
                        height: 4rem;
                        padding: 0;
                        width: 4rem;
                    }
                }

                svg {
                    height: 2.4rem;
                    width: 2.4rem;
                }
            }
        }
    }

    .newsletter__content {
        margin: 3rem 0;
    }

    .order__row {
        margin: 3rem 0;

        .--header {
            align-items: center;
            display: flex;
            flex-wrap: wrap;

            @include breakpoint(medium) {
                gap: 1rem;
            }

            > p {
                align-items: center;
                display: flex;
                gap: 1rem;
                line-height: normal;

                time, span {
                    color: color(gray, Gray-900);
                    font-weight: 500;
                }
            }
        }

        .--actions {
            display: flex;
            gap: 3rem;
            margin: 3rem 0 0;

            a {
                display: inline-flex;
                font-size: 1.5rem;
                font-weight: 500;

                @include hover {
                    text-decoration: underline;
                }
            }
        }
    }

    .order__totals-container {
        display: flex;
        justify-content: flex-end;
        margin: 2rem 0;

        .order__totals {
            li {
                align-items: center;
                display: flex;
                font-size: 1.6rem;
                gap: 6rem;
                justify-content: space-between;
                margin: 1.5rem 0;

                &:last-child {
                    border-top: .1rem solid color(gray, Gray-300);
                    margin: 2rem 0 0;
                    padding: 2rem 0 0;

                    span {
                        font-size: 1.8rem;
                        font-weight: 700;
                    }
                }
            }
        }
    }
}

.customer-account_body {
    .global__messages {
        bottom: 7rem;
    }
}
