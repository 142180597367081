.whats__inside__container {
    padding: 0 !important;

    .whats__inside {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        > div {
            position: relative;
            cursor: pointer;
            overflow: hidden;
            display: none;

            .--overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column;

                > span {
                    text-align: center;
                    color: color(base, White);
                    font-size: 1.5rem;
                    padding: 0.5rem 1rem .2rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    transition: width .5s ease-in-out;
                    position: relative;
                    z-index: 1;
                    width: 100%;
                    right: 0;
                    background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, #171615 40%);
                    overflow: hidden;

                    &:before {
                        content: '';
                        display: block;
                        height: 800px;
                        width: 75%;
                        background: repeating-linear-gradient(90deg, #171615, #171615 4px, transparent 0, transparent 8px);
                        top: -350px;
                        right: 0;
                        position: absolute;
                        z-index: -1;
                        transform: rotate(45deg);
                    }

                    //&::after {
                    //    content: '';
                    //    position: absolute;
                    //    top: 100%;
                    //    left: 50%;
                    //    transform: translateX(-50%);
                    //    border-width: .8rem 1.2rem 0 1.2rem;
                    //    border-color: color(Gray, Gray-600) transparent transparent transparent;
                    //    border-style: solid;
                    //}
                }

                > div {
                    transition: all 0.3s ease;
                    color: color(base, White);
                    font-size: 1.3rem;
                    line-height: 1.2;
                    text-align: center;
                    //border-bottom: .3rem solid color(Gray, Gray-600);
                    background: linear-gradient(180deg, rgba(29, 29, 27, 0.8) 0%, rgba(38, 38, 38, 0.8) 25%, rgba(38, 38, 38, 0.8) 75%, rgba(29, 29, 27, 0.8) 100%);
                    flex: 0;
                    overflow: hidden;

                    .--content {
                        height: 100%;
                        padding: 1rem;

                        > div {
                            height: 100%;
                            margin: 0.5rem 0;

                            a {
                                display: flex;
                                height: 100%;
                                align-items: center;
                                justify-content: center;
                                font-size: 1.6rem;
                                color: color(base, white);
                                position: relative;

                                @include hover() {
                                    text-decoration: underline;
                                }

                                &::after {
                                    z-index: 0;
                                }
                            }
                        }
                    }
                }
            }

            img {
                aspect-ratio: 1 / 1;
            }

            &:hover {
                .--overlay {
                    > div {
                        flex: 1;
                    }
                }
            }
        }
    }

    .button-double {
        margin: 3rem 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        @include button-double();

        p {
            cursor: pointer;
            font-size: 1.6rem !important;

            &:first-of-type::before {
                right: calc(100% - .4rem) !important;
            }
        }

        &.--down {
            .arrow-down {
                height: 1rem;
                transform: rotate(180deg);
            }
        }

        .arrow-down {
            width: 2rem;
            height: 1.3rem;
            display: inline-block;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{color(Dane, White)}" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 1.2rem;
        }
    }
}


.modal__content .global__content {
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;

    h6 {
        font-size: 2rem;
        font-weight: 700;
        margin-right: auto;
    }

    .--button {
        margin: 0 auto;
    }
}
