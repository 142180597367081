.dashboard__autocomplete {
    margin-top: unset;
    overflow-y: scroll;
    position: static;
    width: 100%;

    button {
        justify-content: center;
        margin-top: 2rem;
        text-align: center;
        width: 100%;
    }

    .productCheckbox {
        @include breakpoint(medium) {
            margin-right: 2rem;
        }
        @include breakpoint(small) {
            height: 2rem;
            margin-right: 1rem;
            min-width: 2rem;
            width: 2rem;
        }
    }
}

.dashboard-search__autocomplete-product {
    align-items: center;
    display: flex;
    gap: 2rem;
    transition: .3s all;

    .search__autocomplete-product__content {
        @include breakpoint(small) {
            align-items: unset;
            flex-direction: column;
        }
    }

    &.selected,
    &:hover {
        background: color(Gray, Gray-200);
    }

    &:hover {
        cursor: pointer;
    }
}


.dashboard__search-results {
    width: 100%;
    @include breakpoint(small only) {
        order: 4;
    }

    .search__autocomplete-product__price-block {
        flex-basis: 10%;
    }
}

.previous__search-result {
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 2rem;
    height: 2rem;
    position: absolute;
    right: 6rem;
    top: 1rem;
    width: 2rem;

    &-hide {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M3 4.5h14.25M3 9h9.75M3 13.5h5.25m5.25-.75L17.25 9m0 0L21 12.75M17.25 9v12' /%3E%3C/svg%3E%0A");
    }

    &-show {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M3 4.5h14.25M3 9h9.75M3 13.5h9.75m4.5-4.5v12m0 0l-3.75-3.75M17.25 21L21 17.25' /%3E%3C/svg%3E%0A");
    }

    &:hover {
        cursor: pointer;
    }
}
