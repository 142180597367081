$grid__max-width: 178rem;
$grid__gutter: 2rem;
$grid__max-padding: 2rem;

$grid__breakpoints: (
        small: 0,
        medium: 769,
        large: 1025,
        between-large-medium: 1100,
        xlarge: 1200,
        xxlarge: 1440,
        large-down: 1120,
) !default;

$default-font: 'Red Hat Display', sans-serif;
$fa-font-path: '/fonts';

$color-palette: (
        base: (
                White: rgb(255 255 255),
                Black: rgb(0 0 0),
                Primary: rgb(238 114 3),
                PrimaryHover: darken(rgb(238 114 3), 5%),
                PrimaryButton: rgb(182, 24, 39),
                PrimaryButtonHover: rgb(177, 47, 45),
                SecondaryButton: rgb(255, 102, 0),
                SecondaryButtonHover: rgb(221, 88, 0),
                TertiaryButton: rgb(12, 186, 196),
                TertiaryButtonHover: rgb(10, 156, 164),
                Secondary: rgb(0 189 0),
                SecondaryHover: darken(rgb(0 189 0), 5%),
                DefaultFontColor: rgb(30, 30, 30), // #1E1E1E
                SemiSoldDark: rgb(30, 30, 30, .5),
                Green:rgb(105, 161, 118),
                Yellow: rgb(138, 109, 59),
                Brown: rgb(56, 57, 56),
                Red: rgb(204, 75, 55),
                Gray: rgb(237, 237, 237),
                LightGray: rgb(217, 217, 217),
                DarkGray: rgb(29, 29, 27),
                MediumGray: rgb(198, 198, 198)
        ),
        Dane: (
            Red: rgb(182, 24, 39),
            Black: rgb(0, 0, 0),
            White: rgb(255, 255, 255),
            Gold: rgb(206, 171, 82),
            Silver: rgb(208, 208, 208),
            MeshMembrane: rgb(165, 173, 178),
            Waterproof: rgb(143, 165, 186),
            Mesh: rgb(89, 102, 128),
            DarkGrey: rgb(87, 87, 86)
        ),
        Gray: (
                Gray-25: rgb(250 250 250),
                Gray-50: rgb(232 232 232),
                Gray-100: rgb(245 245 245),
                Gray-125: rgb(245, 245, 245),
                Gray-150: rgb(247, 248, 255),
                Gray-200: rgb(229 229 229),
                Gray-250: rgb(238, 238, 238),
                Gray-300: rgb(192, 192, 192),
                Gray-400: rgb(163 163 163),
                Gray-450: rgba(153, 153, 153, 1),
                Gray-500: rgb(139, 139, 138),
                Gray-600: rgb(82 82 82),
                Gray-700: rgb(60 60 59), //#3C3C3B
                Gray-750: rgb(56, 57, 56),
                Gray-800: rgb(38 38 38),
                Gray-900: rgb(29, 29, 27)
        ),
        alert: (
                Info: rgb(30 66 159),
                Danger: rgb(241, 80, 80),
                Success: rgb(0 101 0),
                Warning: rgb(122 110 16),
                Error: rgb(155 28 28),
                Default: rgb(0 0 0),
        ),
        alert-backgrounds: (
                Info: rgb(195 221 253),
                Danger: rgb(251 213 213),
                Success: rgb(223 255 223),
                Warning: rgb(255 249 205),
                Error: rgb(251 213 213),
                Default: rgb(0 0 0),
        ),
);
