.modal__holder {
    z-index: 9999;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(48, 48, 48, .6);
    display: flex;
    justify-content: center;
    align-items: center;

    .modal-container {
        background: color(base, White);
        min-width: 400px;
        min-height: 150px;
        max-width: 90%;
        max-height: 90%;
        border-radius: 3px;
        box-shadow: 2px 2px 9px 0 rgba(51, 51, 51, 0.8);

        ._header {
            display: flex;
            padding: 0 10px;
            background: color(Gray, Gray-100);
            border-radius: 3px 3px 0 0;
            align-items: center;

            span {
                line-height: 36px;
                font-weight: 600;
                font-size: 14px;
            }

            button {
                margin-left: auto;
                color: color(base, DefaultFontColor);
                width: 2rem;
                height: 2rem;

                &:hover {
                    color: color(base, Black);
                }
            }
        }

        ._content {
            padding: 10px;
            text-align: center;

            ._actions {
                margin: 20px 0 0;
                display: flex;
                justify-content: center;

                button {
                    min-width: 90px;
                    margin: 0 10px;
                    justify-content: center;
                }
            }
        }
    }
}

.off-canvas-module {
    section {
        height: 100%;
        display: flex;
        flex-direction: column;

        .modal__header {
            border-color: color(Gray, Gray-200);
            font-weight: 700;
        }

        .modal__content {
            flex: 1;

            &.--center {
                display: flex;
                flex-flow: column;
                justify-content: center;
            }
        }
    }
}
