.mobile__scrollable {
    .--loading {
        @include loading();
    }

    @include breakpoint(small only) {
        overflow-y: scroll;
        width: 100%;
    }

    table {
        font-size: 1.4rem;
        margin: 0;
        table-layout: auto;
        width: 100%;

        tr:not(.--odd):not(.--even):nth-child(even) {
            background-color: #F2F2F2;
        }

        tr {
            &.--odd {
                background: color(base, White);
            }

            &.--even {
                background: #F2F2F2;
            }
        }

        th {
            background-color: #656565;
            color: color(base, White);
            padding: 5px 8px;
            text-align: left;
            vertical-align: middle;
            width: auto;
            font-weight: 700;
        }

        td {
            max-width: 400px;
            padding: 5px 8px;
            vertical-align: top;
            width: auto;
            @include breakpoint(small only) {
                min-width: 120px;
            }

            input[type='text'], input[type='number'] {
                display: inline;
                font-size: 1.4rem;
                height: 32px;
                line-height: 32px;
                width: 10rem;

                &._qty {
                    border-radius: 3px 0 0 3px;
                    width: 5.4rem;
                }
            }

            &.--stock {
                color: color(alert, Warning);

                &.--green {
                    color: color(alert, Success);
                }
            }

            .qty__actions__holder {
                display: flex;
                align-items: center;
                width: 10rem;

                select {
                    min-width: auto;
                    width: 100%;
                }

                > input {
                    width: initial;
                    max-width: initial;
                    display: block;
                    flex: 0 1 auto;
                }

                .--controls {
                    flex: 0 0 2rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    gap: .2rem;

                    button {
                        @include default-button();
                        line-height: 1.5rem;
                        width: 100%;
                        padding: 0;
                        justify-content: center;
                        align-items: center;
                        background-image: none;

                        &:first-of-type {
                            border-radius: 0 .3rem 0 0;
                        }

                        &:last-of-type {
                            border-radius: 0 0 .3rem 0;
                        }
                    }
                }

                input {
                    max-width: 7.5rem;
                    border-radius: .3rem 0 0 .3rem;
                }
            }

            .vdp-datepicker {
                input {
                    min-width: 14rem;
                }
            }

            select {
                display: inline;
                height: 32px;
                line-height: 32px;
            }

            .vdp-datepicker {
                float: left;

                .vdp-datepicker__calendar-button {
                    line-height: 32px;
                    width: 34px;
                }
            }

            ._max-discount {
                display: inline-block;
                line-height: 32px;
                margin-left: 5px;
            }

            input[type='checkbox'] + label {
                margin: 0;
            }

            img {
                margin: .2rem 0;
            }

            span._error {
                color: color(alert, Danger);
                font-size: 1.2rem;
                line-height: 32px;
                padding-left: 5px;
                @include breakpoint(small only) {
                    display: block;
                    line-height: 14px;
                    padding: 5px 0;
                }
            }
        }
    }

    &._alternative {
        tr:nth-child(odd) {
            border-bottom: none;
        }

        tr {
            &.even {
                background-color: #F2F2F2;
            }

            &.odd {
                background: color(base, White);
            }

            &.quote__options {
                .quote__input__holder {
                    background: #FAFAFA;
                    border: 1px solid #EAEAEA;
                    display: inline-block;
                    padding: 5px 2px;
                    width: max-content;

                    .quote__input {
                        display: flex;
                        flex-direction: column;
                        float: left;
                        padding: 0 8px;

                        label {
                            display: block;
                            font-size: 1.1rem;
                            font-weight: 600;
                        }

                        input {
                            width: 90px;
                        }

                        .qty__actions__holder {
                            float: left;

                            span {
                                @include default-button();
                                border-radius: 0 0 3px 0;
                                border-top: 0.031em solid color(base, White);
                                display: block;
                                height: 16px;
                                line-height: 14px;
                                padding: 0;
                                text-align: center;
                                width: 24px;

                                &:first-child {
                                    border-bottom: 0.031em solid color(base, White);
                                    border-radius: 0 3px 0 0;
                                    border-top: none;
                                }
                            }
                        }

                        .vdp-datepicker {
                            .vdp-datepicker__calendar-button {
                                line-height: 32px;
                                width: 34px;
                            }
                        }

                        &._round {
                            input {
                                border-radius: 3px 0 0 3px;
                                float: left;
                                width: 75px;
                            }
                        }
                    }
                }

                &._service {
                    .quote__input__holder {
                        display: flex;
                        margin: 0 0 5px;

                        label {
                            margin: 0 0 5px;
                        }

                        .input__box {
                            padding: 0 0 5px;

                            input {
                                width: 100%;
                            }
                        }

                        ._actions button {
                            float: right;
                            margin-left: 10px;
                        }

                        ._error {
                            display: block;
                            line-height: normal;
                            padding: 0;
                        }
                    }
                }
            }

            td.a-center {
                text-align: center;
            }
        }
    }
}
