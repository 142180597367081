.wrapper {
    padding: 0 0 3rem;
    .home_body & {
        padding: 0;
    }
}
.content,
.--content {
    p, li {
        font-size: 1.6rem;
    }
}
.global__content,
.content,
.--content,
.dashboard__addresses,
form,
.checkout-container,
.checkout-cart {
    a:not([class]),
    a.--forget,
    a.continue__shopping{
        color: #595A5C;
        display: inline-block;
        position: relative;
        font-size: 1.6rem;

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: -0.2rem;
            right: -0.2rem;
            width: 0;
            height: 0.2rem;
            background-color: color(base, PrimaryButton);
            transition: width 0.5s ease;
            z-index: -1;

        }
        &:hover {
            text-decoration: none !important;
            &:after {
                width: calc(100% + 0.4rem);
            }
        }
    }

}
.content {
    &.std {
        ul {
            margin: 1rem 0;
        }
        li {
            font-size: 1.6rem;
            line-height: 2.6rem;
            &.title {
                font-weight: 700;
            }
        }
    }
    h1 {
        display: inline-block;

        &:after {
            content: '';
            display: block;
            @include rev-counter;
            margin-bottom: 12px;
        }

        &:hover {
            &:after {
                @include rev-counter-hover();
            }
        }
    }
}
