._category_description_content {
    position: relative;

    &.no-image {
        margin-bottom: 2rem;

        h1 {
            position: relative;
            padding: 0 0 1.2rem 0;
            display: inline-block;
        }
    }

    h1 {
        left: 0;
        top: 0;
        z-index: 1;
        background: color(base, White);
        padding: 1.2rem;

        &::after {
            content: '';
            display: block;
            background-image: linear-gradient(90deg, #00a4e5, #00a4e5 100%, #e85121 0%, #bd1724 0%, #dadada 0, #dadada);
        }
    }

    .image_container {
        position: relative;
        margin-bottom: 2.4rem;
        overflow: hidden;

        &::before {
            content: '';
            padding-top: 33%;
            display: block;
            @include breakpoint(small only) {
                padding-top: 60%;
            }
        }

        img {
            position: absolute;
            width: 100%;
            height: 100%;
            object-fit: cover;
            left: 0;
            top: 0;
        }
    }

    p {
        font-size: 1.8rem;
        margin-bottom: 2.4rem;
    }
}

.catalog-category__toolbar {
    .toolbar__top {
        .--actions {
            display: flex;
            justify-content: space-between;
            font-size: 1.6rem;
        }
    }
}

.catalog__category-list {
    overflow: hidden;

    .--loading {
        z-index: 6;
    }

    > ul {
        margin: 0;
    }

    .grid-x.grid-padding-x {
        margin-right: -2rem;

        @include breakpoint(medium) {
            margin-right: -3rem;

            .cell {
                padding-left: 3rem;
                padding-right: 3rem;
            }
        }
    }

    .grid-x {
        border: none;

        .product__tile {
            width: 100%;
            height: 100%;
            border: none;
            display: block;
            padding-bottom: 0;
            margin-bottom: 0;

            @include breakpoint(medium) {
                margin-bottom: 3rem;
            }

            > a {
                display: block;
                position: relative;
                @include hover() {
                    .image__holder {
                        img {
                            transform: scale(1.1);
                        }
                    }
                }
            }

            .bg-container {
                position: absolute;
                background: repeating-linear-gradient( -45deg, #CEAB52, #CEAB52 5px, #FFFFFF 0px, #FFFFFF 10px );
                opacity: 0.1;
                top: 4.6rem;
                bottom: 0;
                left: 0;
                right: 2rem;
                z-index: 0;
                border-top: 4.4rem solid color(Dane, White);

                @include breakpoint(medium) {
                    top: 7.6rem;
                    bottom: 3rem;
                }
            }

            .product-tile__container {
                position: relative;
                z-index: 2;
                display: flex;

                .content {
                    flex: 1;

                    @include breakpoint(medium) {
                        padding-top: 3rem;
                        padding-bottom: 3rem;
                    }


                    &-inner {
                        display: flex;
                        flex-direction: column;
                        justify-content: space-between;
                        height: 100%;
                    }

                    ._product_title_container {
                        position: relative;
                        background: color(base, White);
                        display: flex;
                        align-items: flex-end;
                        height: 9rem;

                        h3 {
                            padding: 1rem 2rem;
                            color: color(base, DarkGray);
                            font-weight: 900;
                            font-size: 2.4rem;
                            line-height: 1.1;
                            width: 100%;

                            @include breakpoint(small only) {
                                font-size: 1.8rem;
                            }
                        }
                    }

                    .product__holder {

                        flex: 1;
                        text-align: left;
                        padding: 0 0 5rem 2rem;
                        height: auto;
                        justify-content: flex-end;
                        min-height: 15rem;

                        @include breakpoint(medium) {
                            min-height: 20rem;
                        }

                        > *:first-child {
                            flex: 1;
                        }

                        ul {
                            @include transition();
                            opacity: .2;
                            margin: 1rem 0 0;

                            li {
                                text-align: left;
                                justify-content: flex-start;
                                align-items: baseline;
                                padding: .2rem 0;

                                &::before {
                                    background: color(Dane, Red);
                                    height: .6rem;
                                    flex: 0 0 .6rem;
                                    margin-top: .8rem;
                                }
                            }
                        }

                        .view__details {
                            text-align: left;

                            a {
                                @include transition();
                                font-size: 1.6rem;
                                font-weight: 800;
                                background-color: color(Dane, Red);
                                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="7" viewBox="0 0 256 512"><path fill="%23ffffff" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>');
                                background-repeat: no-repeat;
                                background-position: right 1.4rem center;
                                color: color(Dane, White);
                                text-decoration: none;
                                display: inline-block;
                                padding: .4rem 2.4rem .4rem 1rem;
                                text-transform: uppercase;


                                &:hover {
                                    background-color: darken(color(Dane, Red), 5%);
                                }

                                &::after {
                                    display: none;
                                }

                                @include breakpoint(small only) {
                                    font-size: 1.2rem;
                                    padding: .4rem 1.5rem;
                                }
                            }
                        }

                        .price__holder {
                            position: absolute;
                            bottom: 2rem;
                            left: 0;
                            width: 100%;
                            background-color: color(Dane, White);
                            padding: 1rem 2rem;
                            @include breakpoint(small only) {
                                bottom: 0;
                            }

                            .price {
                                font-weight: 900;
                            }
                        }
                    }
                }

                .image__holder {
                    transition: all 0.5s ease;
                    opacity: .6;
                    width: 60%;
                    margin: 0;
                    transform: scale(0.6);

                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }

            .product__swatches {
                width: 70%;
                margin: 0 auto;

                .swiper-slide {
                    transition: opacity .5s ease-in-out, transform .5s ease-in-out;
                    opacity: .5;
                    transform: scale(.8);

                    > span {
                        display: block;
                        border-bottom: .2rem solid transparent;
                    }

                    &.swiper-slide-active {
                        opacity: 1;
                        transform: scale(1);

                        > span {
                            border-color: color(Dane, Red);
                        }
                    }

                    &:not(.swiper-slide-active) span:hover {
                        border-color: transparent !important;
                    }
                }
            }
        }

        .--in-view {
            .product-tile__container {
                .content {
                    .product__holder ul {
                        opacity: 1;
                    }
                }

                .image__holder {
                    transform: scale(1);
                    opacity: 1;
                }
            }
        }
    }
}
