html,
body {
    color: color(base, DefaultFontColor);
    font-family: $default-font;
    font-size: 62.5%;
    font-style: normal;
    font-weight: 500;
}

.button, ._button {
    @include default-button();
}

.button-send {
    @include default-button();
    width: max-content;
}

.grid-container {
    max-width: 150rem;

    &.--fullscreen {
        max-width: 100%;
        padding: 0;
    }

    @include breakpoint(small only) {
        &.no-padding-mobile {
            padding: 0;
        }
    }
}

span {
    font-size: 1.4rem;
}

h1, h2, h3 {
    font-weight: 700;
    text-transform: uppercase;
}

h1 {
    font-size: 2.4rem;

    @include breakpoint(medium) {
        font-size: 3.2rem;
    }
}

h2 {
    font-size: 2.4rem;
}

h3 {
    font-size: 1.9rem;
}

h4 {
    font-size: 1.6rem;
    font-weight: 700;
}

a {
    font-size: 1.2rem;
}

li {
    font-size: 1.4rem;
}

p {
    font-size: 1.4rem;
}

.vdp-datepicker__calendar .cell {
    width: 14.285714285714286% !important;

    &.month, &.year {
        width: 33.333334% !important;
    }
}

.video__holder {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;

    &.--fullscreen {
        pointer-events: none;

        @media (min-aspect-ratio: 16/9) {
            height: 300%;
            top: -100%;
        }

        @media (max-aspect-ratio: 16/9) {
            left: -100%;
            width: 300%;

            @include breakpoint(small only) {
                left: -150%;
                width: 400%;
            }
        }
    }

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.--video-holder {
    height: 0;
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    width: 100%;

    iframe {
        height: 100%;
        left: 0;
        position: absolute;
        top: 0;
        width: 100%;
    }
}

.loading__bar {
    border: .1rem solid #e7e7e7;
    background: linear-gradient(0deg, #dedede, #f1f1f1);
    height: 2rem;
    width: 100%;
    border-radius: 1rem;
    padding: .3rem .4rem;

    span {
        display: block;
        background: linear-gradient(0deg, color(base, PrimaryButton), darken(color(base, PrimaryButton), 10%));
        height: 100%;
        border-radius: .6rem;
    }
}

.no-scroll {
    position: relative;
    width: 100%;
    height: 100vh;
}

.wrapper.--has-dashboard {
    @media screen and (max-width: 1600px) {
        @include breakpoint(medium) {
            padding-left: 5rem;
        }
    }
}

.quick__buy__sorter {
    display: flex;
    justify-content: flex-end;

    > div {
        display: flex;
        align-items: center;
        gap: .5rem;

        label {
            font-size: 1.6rem;
        }

        select {
            min-width: auto;
        }
    }
}

.customer__switcher {
    .--required {
        > * {
            border-color: color(alert, Error);
        }
    }

    > span {
        display: inline-block;
        margin: .5rem 0 0;
    }
}

.scroll-to-top-button {
    @include transition();
    align-items: center;
    background-color: color(base, Primary);
    border-radius: 50%;
    bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    color: color(base, White);
    cursor: pointer;
    display: flex;
    height: 40px;
    justify-content: center;
    position: fixed;
    right: 20px;
    width: 40px;
    z-index: 99;

    svg {
        width: 2.4rem;
    }

    @include hover() {
        background-color: color(base, PrimaryHover);
    }
}

.modal__holder.full-screen--overlay {
    display: none;

    .modal-container {
        @include breakpoint(medium) {
            max-width: 50rem;
        }

        ._content {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            gap: 2rem;
            padding: 2rem;
            font-size: 1.6rem;
            line-height: 1.5;

            p {
                font-size: 1.6rem;
                line-height: 1.5;
            }

            img {
                margin: 0 auto;
            }

            .--loading {
                @include loading();
            }
        }
    }
}
