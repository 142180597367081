header nav {
    display: flex;
    gap: 2rem;
    > ul {
        display: flex;
        gap: 2rem;

        > li {
            position: relative;
            > a, > span {
                color: color(Dane, Black);
                font-size: 1.4rem;
                text-transform: uppercase;
                font-weight: 800;
                &::after {
                    content: '';
                    @include rev-counter();
                    display: block;
                }

                &:hover {
                    cursor: pointer;
                    &::after {
                        @include rev-counter-hover();
                    }
                }
            }

            .--dropdown {
                transition: all .4s ease-in-out;
                position: absolute;
                background-color: lighten(saturate(adjust-hue(color(Dane, MeshMembrane), 7), 0.22), 22.94);
                left: -1.2rem;
                top: 2.2rem;
                opacity: 0;
                transform: translateX(-120%);
                filter: blur(.5rem);
                margin: 0 auto;
                min-height: 25rem;
                z-index: 99;
                max-height: 80vh;
                min-width: max-content;
                overflow-y: auto;
                overflow-x: hidden;
                display: none;

                > div {
                    padding: 3rem;
                    display: flex;

                    > .grid-container {
                        flex-direction: column;
                        gap: 1rem;

                        .column > span, .hide-title > span {
                            display: none;
                        }

                        .spacer > span, .spacer > a {
                            display: none;

                            @include breakpoint(medium) {
                                display: block;
                                height: 2.4rem;
                                cursor: default;
                            }
                        }

                        .--group, > ul {
                            a {
                                font-size: 1.2rem;
                                line-height: 2rem;
                                color: color(Dane, Black);

                                @include hover() {
                                    font-weight: 600;
                                }
                            }

                            .navigation__solar > a, {
                                font-weight: 700 !important;
                                font-size: 2rem;
                                font-style: normal !important;

                                @include hover() {
                                    font-weight: 700;
                                }
                            }

                            .navigation__group {
                                > ul:not(:last-of-type) {
                                    margin: 0 0 2rem;
                                }

                                > a, > span {
                                    cursor: pointer;
                                    font-weight: 300;
                                    font-size: 1.2rem;

                                    @include hover() {
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }

                @media (max-height: 700px) {
                    > div {
                        padding: 2rem 2rem 2rem 8rem;

                        > .grid-container {
                            > ul a {
                                font-size: 1.3rem !important;
                                line-height: 1.3 !important;
                            }

                            .--group, > ul {
                                .navigation__solar > a, > a {
                                    font-size: 1.4rem !important;
                                    margin-bottom: 0;
                                }
                            }

                            .navigation__group {
                                > a, > span {
                                    font-size: 1.4rem !important;
                                }
                            }
                        }
                    }
                }
                .--has-children {
                    ul li {
                        list-style: square inside;
                        color: color(Dane, Red);
                    }
                }
            }

            &:nth-last-child(-n + 2) {
                .--dropdown {
                    right: -1.2rem;
                    left: auto;
                }
            }
            &.--active {
                > a, > span {
                    &::after {
                        @include rev-counter-hover();
                    }
                }
                .--dropdown {
                    transform: translateX(0);
                    filter: blur(0);
                    opacity: 1;
                    display: block;
                }
            }
        }
    }
}

.off-canvas {
    &.--menu {
        .modal__header {
            color: color(Dane, White);
            > span {
                font-style: normal;
                font-size: 1.6rem;
            }
            > button {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='%23FFFFFF' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18 18 6M6 6l12 12'/%3E%3C/svg%3E");
                background-repeat: no-repeat;
                height: 2.4rem;
                margin-left: auto;
                position: static;
                width: 2.4rem;
            }
        }
    }
}
