header nav > ul > li .--dropdown > div {
    > .grid-container {
        width: 100%;
        max-width: 100%;
        display: flex;
        gap: 3rem;

        > * {
            flex: 1;

            &:not(:last-child) {
                margin-right: 3rem;
                padding-right: 3rem;
                border-right: .1rem solid color(gray, Gray-200);
            }
        }

        > ul {
            .navigation__solar > a, > ul > a {
                font-weight: 900;
                font-size: 2.4rem;
                display: inline-block;
                margin-bottom: 1rem;
                font-style: italic;

                &::after {
                    content: '';
                    @include rev-counter();
                    display: block;
                }
            }

            .navigation__solar, > ul {
                @include hover() {
                    > a {
                        font-weight: 900;

                        &::after {
                            @include rev-counter-hover();
                        }
                    }
                }
            }

            a {
                display: inline-block;
                line-height: 1.5;
                font-size: 1.6rem;

            }
        }
    }
}

.off-canvas.--menu {
    background-color: color(Gray, Gray-100);

    .modal__header {
        background-color: color(base, PrimaryButton);
        border-bottom: none;
        height: 4.6rem;

        > span {
            text-transform: uppercase;
            font-weight: 900;
            font-style: italic;
        }
    }

    .menu {
        margin: 1rem 0 0;

        .js-drilldown-back {
            display: none;
        }

        .--dropdown {
            display: none;

            .grid-container {
                padding: 0;

                .invisible {
                    visibility: visible;
                }

                .column > span {
                    display: none;
                }

                ul {
                    margin-left: 1rem;

                    li {
                        a, span {
                            font-size: 1.4rem;
                            line-height: 2.4rem;

                            &::before {
                                content: '- ';
                            }
                        }
                    }
                }
            }
        }

        > ul > li {
            a, span {
                color: color(base, DefaultFontColor);
                border-bottom: none;
                padding: 0 2rem;
                line-height: 3.2rem;
                text-transform: uppercase;
                font-size: 1.6rem;
                font-weight: 700;
            }

            &.--active {
                > a, > span {
                    text-decoration: underline;
                }

                .--dropdown {
                    display: block;
                }
            }

            .spacer > a, .spacer > span {
                display: none;
            }

            .navigation__image {
                margin: 3rem 0;
            }
        }
    }

    .language__container {
        > span {
            display: none;
        }

        .language__dropdown {
            padding: 0 0.5rem;
            margin: 2rem 0 0;

            ul {
                display: flex;
                flex-wrap: wrap;

                li {
                    a {
                        color: color(base, DefaultFontColor);
                        line-height: 3.2rem;
                        text-transform: uppercase;
                        font-size: 1.6rem;
                        font-weight: 700;
                        border-bottom: none;
                    }
                }
            }
        }
    }
}
