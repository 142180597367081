.homepage__fullscreen-store-locator--block-wrapper {
    height: 100%;

    section {
        height: 100%;
        position: relative;

        @include breakpoint(small only) {
            display: flex;
            flex-flow: column;
        }

        .vue-map-container {
            width: 100%;
            height: 100%;

            .--actions {
                a {
                    display: block;
                    color: color(base, Black);
                    text-decoration: underline;
                    font-size: 1.6rem;
                    line-height: 2.2rem;
                }
            }
        }

        .store-locator__form {
            background: color(base, White);
            padding: 0 1rem 1.6rem 2rem;
            position: relative;

            @include breakpoint(medium) {
                position: absolute;
                z-index: 1;
                left: 0;
                top: calc(50% - 4rem);
                padding: 0 2rem 3.2rem 10rem;
            }

            .--loading {
                @include absolute-loading();
            }

            h3 {
                > span {
                    font-size: 3.4rem;
                    padding: 1rem 1.2rem .5rem 0;
                    text-align: left;

                    @include breakpoint(medium) {
                        font-size: 5rem;
                        padding: 2rem 2.5rem 1rem 0;
                        text-align: right;
                    }
                }
            }

            select {
                width: 100%;
                text-align: left;
            }

            .store__filter {
                ul {
                    margin: 1rem 0 0;

                    li, li label {
                        &:is(li) {
                            padding: .5rem 0;
                        }

                        display: flex;
                        align-items: center;
                        gap: 1rem;

                        input, label {
                            cursor: pointer;
                        }
                    }

                    label {
                        @include hover() {
                            text-decoration: underline;
                        }
                    }
                }
            }
        }
    }
}
