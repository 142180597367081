.homepage__image-slider--block-wrapper {
    h2 {
        text-transform: uppercase;
        font-size: clamp(5.1rem, 5vw, 8rem);
        line-height: 1;
        font-weight: 900;
        font-style: italic;
        text-align: center;
    }

    h3 {
        text-transform: uppercase;
        font-size: clamp(2.8rem, 5vw, 4rem);
        line-height: 1;
        font-weight: 500;
        text-align: center;
    }

    section {
        display: flex;
        gap: 4rem;
        align-items: center;
        margin: 4rem 0 0;

        .swiper-slide {
            > a, > span {
                span {
                    height: 0;
                    transition: .5s ease;
                    background: rgba(48, 48, 48, .4);
                    position: absolute;
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    color: color(base, White);
                    font-size: 2.2rem;
                    text-indent: -70rem;
                    overflow: hidden;
                    z-index: 1;
                }

                @include breakpoint(medium) {
                    img {
                        width: 63.6rem;
                        height: 36rem;
                        object-fit: cover;
                    }
                }
            }

            @include hover() {
                > a, > span {
                    span {
                        height: 100%;
                        text-indent: 0;
                    }
                }
            }
        }

        .--swiper-button {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
            flex: 0 0 5rem;
            height: 5rem;

            &.--next {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
            }
        }
    }

    .--swiper-pagination {
        display: flex;
        justify-content: center;
        margin: 4rem 0 0;

        .swiper-pagination-bullet {
            width: 1rem;
            height: 1rem;
            text-indent: -999rem;
            background: color(Gray, Gray-900);
            border-radius: 50%;
            opacity: 1;

            &.swiper-pagination-bullet-active {
                background: color(base, Primary);
            }
        }
    }
}
