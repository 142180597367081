.invoice__portal-container {
    .invoice__type {
        margin: 2rem 0 0;

        h4 {
            margin: 0 0 .5rem;
        }
    }

    .message__holder {
        margin: 1rem 0;
    }

    .invoice__results {
        margin: 1rem 0;

        h4 {
            margin: 0 0 1rem;
        }

        .mobile__scrollable {
            table td {
                vertical-align: middle;

                &.a-right {
                    text-align: right;
                }
            }
        }
    }
}
