.product__view .store-locator-section {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 4.8rem 0;

    .vue-map-container {
        height: 20vh;
        min-height: 40rem;
        flex: 0 0 100%;
        order: 2;

        @include breakpoint(small only) {
            margin: 0;
            flex: 0 0 100%;
        }

        @include breakpoint(medium only) {
            margin-left: .6rem;
            margin-right: .6rem;
            flex: 0 0 calc(100% - 1.2rem);
        }

        .vue-map {
            border-radius: 0 0 15px 15px;
        }

        .--actions {
            a {
                display: block;
                color: color(base, Black);
                text-decoration: underline;
                font-size: 1.6rem;
                line-height: 2.2rem;
            }
        }
    }

    .store-locator__form {
        flex: 0 0 100%;
        order: 1;
        margin-bottom: 24px;
        margin-top: 60px;

        .--animation {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 2rem;
            @include breakpoint(small only) {
                form {
                    width: 100%;
                }
            }

            h3 {
                padding: 0.5rem 1rem;
                display: inline-block;
                color: color(base, DarkGray);
                text-transform: uppercase;
                width: 100%;

                svg {
                    margin-right: 0.5rem;
                    margin-top: 0.8rem;
                }

                > span {
                    font-size: 3rem;
                    position: relative;
                    z-index: 4;
                    font-weight: 900;

                    strong {
                        font-weight: 900;
                    }
                    @include breakpoint(small only) {
                        font-size: 2rem;
                    }
                }
            }
            form {
                position: relative;
                &:after {
                    content: '';
                    display: block;
                    width: 3rem;
                    height: 100%;
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{color(Dane, White)}" d="M137.4 41.4c12.5-12.5 32.8-12.5 45.3 0l128 128c9.2 9.2 11.9 22.9 6.9 34.9s-16.6 19.8-29.6 19.8H32c-12.9 0-24.6-7.8-29.6-19.8s-2.2-25.7 6.9-34.9l128-128zm0 429.3l-128-128c-9.2-9.2-11.9-22.9-6.9-34.9s16.6-19.8 29.6-19.8H288c12.9 0 24.6 7.8 29.6 19.8s2.2 25.7-6.9 34.9l-128 128c-12.5 12.5-32.8 12.5-45.3 0z"/></svg>');
                    background-position: right .8rem center;
                    background-repeat: no-repeat;
                    background-size: 1rem;
                    position: absolute;
                    right: 0;
                    top: 0;
                    pointer-events: none;
                }
            }
            select {
                background-image: none;
                border: 2px solid color(Dane, Mesh);
                color: color(Dane, Mesh);
                font-weight: 700;
                min-width: 0;
                padding-right: 36px;
                box-shadow: inset -2.4rem 0px 0px 0px color(Dane, Mesh);
                position: relative;


                @include breakpoint(small only) {
                    width: 100%;
                    margin-bottom: 24px;
                }
            }

            .store__filter {
                margin-left: 48px;
                @include breakpoint(small only) {
                    margin-left: 0;
                }

                ul {
                    display: flex;

                    li {
                        padding: 0 6px;

                        input[type="checkbox"] {
                            display: none;
                        }

                        input[type="checkbox"]:checked + label {
                            opacity: 1;
                        }

                        label {
                            opacity: .5;
                            display: flex;
                            cursor: pointer;
                            justify-content: center;
                            align-items: center;
                            flex-direction: row-reverse;
                            font-weight: 700;
                            font-style: italic;
                            font-size: 1.2rem;

                            img {
                                width: 30px;
                                margin-bottom: 0.2rem;
                                margin-right: 0.5rem;
                            }
                        }
                    }
                }
            }
        }
    }
}

.homepage__fullscreen-store-locator--block-wrapper {
    .store-locator-section {
        h3 {
            padding: 0.5rem 1rem;
            display: inline-block;
            color: color(base, DarkGray);
            text-transform: uppercase;
            width: 100%;

            svg {
                margin-right: 0.5rem;
                margin-top: 0.8rem;
            }

            > span {
                font-size: 3rem;
                position: relative;
                z-index: 4;
                font-weight: 900;

                strong {
                    font-weight: 900;
                }
                @include breakpoint(small only) {
                    font-size: 2rem;
                }
            }
        }
    }
}
