.product-attributes {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    flex: 1;

    ul {
        width: 100%;
        margin: 0;

        li {
            text-indent: initial;
            padding: 0;
        }
    }

    .attribute {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;

        span {
            flex-basis: 50%;
            font-size: 1.2rem;

            &:first-of-type {
                text-transform: uppercase;
            }

            &:last-of-type {
                font-weight: 700;
            }
        }
    }
}
