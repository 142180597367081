.report__portal-container {
    .--option {
        display: flex;
        align-items: center;
        gap: 1rem;

        .--icon {
            width: 2rem;
            height: 2rem;
            display: block;
        }
    }

    .report__dashboard {
        .report__parameters {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            gap: 2rem;

            > div {
                label:not(.--button) {
                    display: block;
                    margin: .5rem 0 .2rem;
                    font-size: 1.4rem;

                    em {
                        font-size: 1.2rem;
                        font-style: normal;
                        color: color(alert, Error);
                    }
                }

                .input__box {
                    input, > select, > textarea {
                        width: 100%;
                        min-width: auto;
                        font-size: 1.4rem;
                        line-height: 3rem;
                    }
                }
            }
        }

        .--actions {
            display: flex;
            align-items: center;
            gap: 2rem;
        }

        .report__result {
            margin: 2rem 0;
            padding: 2rem 0 0;
            border-top: .2rem solid color(base, DefaultFontColor);

            .--header {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 0 0 2rem;

                .--export {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    button {
                        width: 2.5rem;
                        height: 2.5rem;
                        background-repeat: no-repeat;
                        background-position: center;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{color(base, PrimaryButton)}' height='16' width='12' viewBox='0 0 384 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z'/%3E%3C/svg%3E");
                        background-size: contain;

                        &:disabled {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{color(Gray, Gray-300)}' height='16' width='12' viewBox='0 0 384 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z'/%3E%3C/svg%3E");
                        }
                    }
                }
            }

            td.--detail {
                padding: 1rem 1rem 2rem 1rem;
                background: color(base, White);

                > div {
                    @include border-radius(.6rem);
                    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
                    overflow: hidden;
                    border: .1rem solid #EAEAEA;

                    table {
                        border: none;

                        tr:last-of-type {
                            border: none;
                        }
                    }
                }
            }

            table {
                tbody {
                    table {
                        border: .1rem solid color(Gray, Gray-300);

                        th {
                            background-color: color(base, White);
                            color: color(base, Black);
                        }

                        td {
                            background-color: color(base, White);
                        }
                    }

                    &.--open {
                        > tr:first-child {
                            border-bottom: 0;
                        }
                    }

                    &.--clickable {
                        > tr:first-child {
                            cursor: pointer;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m19.5 8.25-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                            background-repeat: no-repeat;
                            background-position: calc(100% - 1rem) center;
                            background-size: 1.8rem;
                        }

                        &.--open > tr:first-child {
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 15.75 7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                        }
                    }

                    &.--loading {
                        > tr:first-child {
                            cursor: wait;
                            background-image: url("data:image/svg+xml,%0A%3Csvg width='135' height='140' viewBox='0 0 135 140' xmlns='http://www.w3.org/2000/svg' fill='%23303030'%3E%3Crect y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.5s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.5s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='30' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.25s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.25s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='60' width='15' height='140' rx='6'%3E%3Canimate attributeName='height' begin='0s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='90' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.25s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.25s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='120' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.5s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.5s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3C/svg%3E");
                        }
                    }
                }
            }
        }
    }
}
