.referenced__entities {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    .referenced__entity {
        flex: 0 0 calc(50% - 2rem);
        &--label {
            font-size: 1.6rem;
        }
        h4 {
            font-size: 1.9rem;
            font-weight: 700;
            line-height: 3.6rem;
            margin: 0;
            text-transform: uppercase;
        }
        &--single {
            align-items: flex-start;
            display: flex;
            gap: 1rem;
            .tooltip__wrapper {
                background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 35.58 18.15' style='enable-background:new 0 0 35.58 18.15;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D1D1B;%7D%0A%3C/style%3E%3Cg%3E%3Cpath class='st0' d='M29.18,18.15H1.25c-0.45,0-0.85-0.23-1.08-0.62c-0.23-0.39-0.23-0.85-0.01-1.24l1.98-3.48 c0.35-0.61,0.94-1.07,1.63-1.25l11.07-2.81c0.29-0.07,0.55-0.27,0.7-0.53l3.96-6.86C19.98,0.52,20.89,0,21.86,0h7.32 c0.97,0,1.88,0.52,2.37,1.37l3.66,6.34c0.49,0.84,0.49,1.89,0,2.73l-3.66,6.34C31.06,17.62,30.16,18.15,29.18,18.15z M21.86,1 c-0.62,0-1.19,0.33-1.5,0.87L16.4,8.73c-0.29,0.49-0.77,0.86-1.32,1L4.02,12.53c-0.42,0.11-0.79,0.39-1.01,0.77l-1.97,3.47 c-0.06,0.11-0.03,0.2,0,0.24c0.02,0.04,0.08,0.12,0.21,0.12h27.94c0.62,0,1.19-0.33,1.5-0.87l3.66-6.34c0.31-0.53,0.31-1.2,0-1.73 l-3.66-6.34C30.38,1.33,29.8,1,29.18,1H21.86z'/%3E%3Cpath class='st0' d='M32.75,8.2l-2.86-4.95C29.58,2.72,29,2.38,28.38,2.38h-5.71c-0.62,0-1.2,0.33-1.51,0.87L18.3,8.2 c-0.31,0.54-0.31,1.2,0,1.74l2.86,4.95c0.31,0.54,0.89,0.87,1.51,0.87h5.71c0.62,0,1.2-0.33,1.51-0.87l2.86-4.95 C33.06,9.41,33.06,8.74,32.75,8.2z M26.65,13.8h-1.99V7.15h1.99V13.8z M26.45,6.23c-0.21,0.21-0.47,0.32-0.77,0.32 s-0.56-0.1-0.78-0.32s-0.32-0.47-0.32-0.79c0-0.3,0.11-0.56,0.32-0.77s0.47-0.32,0.78-0.32s0.56,0.1,0.77,0.32 c0.21,0.21,0.32,0.47,0.32,0.77C26.76,5.75,26.66,6.02,26.45,6.23z'/%3E%3C/g%3E%3C/svg%3E%0A");
                background-repeat: no-repeat;
                background-size: 2rem;
                height: 1.6rem;
                position: relative;
                flex: 0 0 2rem;
                margin-top: 4px;
                &:hover {
                    cursor: pointer;
                }
            }
            .--tooltip {
                padding: 2rem;
                img {
                    margin: 1rem 0;
                }
                .--button {
                    margin: 1rem 0;
                }
                &__description {
                    margin: 1rem 0;
                }
                span {
                    font-size: 1.6rem;
                }
            }
        }
    }
}
