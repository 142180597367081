@import 'customer/downloads',
'customer/pre-order',
'customer/account',
'customer/quick-buy',
'customer/copy-invoice',
'customer/service',
'customer/dashboard',
'customer/search',
'customer/report';

.top__header {
    display: flex;
    align-items: center;
    gap: 1rem;

    > button {
        color: color(base, Primary);
        margin-left: auto;
        font-size: 1.4rem;
        font-weight: 500;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{color(base, Primary)}' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--%3E%3Cpath d='M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM216 336h24V272H216c-13.3 0-24-10.7-24-24s10.7-24 24-24h48c13.3 0 24 10.7 24 24v88h8c13.3 0 24 10.7 24 24s-10.7 24-24 24H216c-13.3 0-24-10.7-24-24s10.7-24 24-24zm40-208a32 32 0 1 1 0 64 32 32 0 1 1 0-64z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-position: left center;
        background-size: 1.5rem;
        padding: 0 0 0 2.3rem;
        line-height: 1.9;

        @include hover() {
            text-decoration: underline;
        }
    }
}
