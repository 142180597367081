.open {
    left: 0 !important;
    transition: all 0.4s ease;
    #sticky__account-menu-trigger {
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 206.34 128.17' style='enable-background:new 0 0 206.34 128.17;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M206.34,103.17c0,6.4-2.44,12.8-7.32,17.68c-9.76,9.76-25.59,9.76-35.36,0l-60.49-60.49l-60.49,60.49 c-9.76,9.76-25.59,9.76-35.36,0c-9.76-9.76-9.76-25.59,0-35.36L85.49,7.32C90.18,2.63,96.54,0,103.17,0s12.99,2.63,17.68,7.32 l78.17,78.17C203.9,90.37,206.34,96.77,206.34,103.17z'/%3E%3C/svg%3E%0A");
    }
}

.sticky__account-menu--wrapper {
    float: left;
    left: -20rem;
    margin-left: -20rem;
    position: sticky;
    top: 25%;
    transition: all 0.4s ease;
    z-index: 6;
}

#sticky__account-menu-trigger {
    @include breakpoint(medium) {
        background-color: color(base, Primary);
        border-bottom-left-radius: .6rem;
        border-bottom-right-radius: .6rem;
        color: color(base, White);
        cursor: pointer;
        font-weight: 700;
        left: 14rem;
        padding: 1rem;
        position: absolute;
        text-align: center;
        top: 16rem;
        transform: rotate(-90deg);
        width: 15rem;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 206.34 128.17' style='enable-background:new 0 0 206.34 128.17;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23FFFFFF;%7D%0A%3C/style%3E%3Cpath class='st0' d='M0,25c0-6.4,2.44-12.8,7.32-17.68c9.76-9.76,25.59-9.76,35.36,0l60.49,60.49l60.49-60.49 c9.76-9.76,25.59-9.76,35.36,0c9.76,9.76,9.76,25.59,0,35.36l-78.17,78.17c-4.69,4.69-11.05,7.32-17.68,7.32s-12.99-2.63-17.68-7.32 L7.32,42.68C2.44,37.8,0,31.4,0,25z'/%3E%3C/svg%3E%0A");
        background-position: center left 17px;
        background-size: 10px;
        background-repeat: no-repeat;
        &:hover {
            background-color: color(base, PrimaryHover);
        }
    }
}

.sticky__account-menu {
    background-color: color(Gray, Gray-100);
    border-radius: 0 .6rem .6rem 0;
    position: relative;
    top: 10rem;
    width: 20rem;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 1px 1px;

    ul {

        &:last-of-type {
            margin: 1rem 0 0;
        }

        li {
            cursor: pointer;
            height: 3.5rem;
            padding: 1rem 0 1rem 1rem;
            position: relative;
            z-index: 2;

            a {
                align-items: center;
                display: flex;
                inset: 0;
                padding-left: 1rem;
                position: absolute;

                &::before {
                    content: '';
                    display: inline-block;
                    height: 2rem;
                    width: 0.2rem;
                    background: color(Gray, Gray-100);
                    margin-right: 0.6rem;
                }
            }

            &.--active {
                a {
                    font-weight: 700;
                    &::before {
                        background: color(base, Primary);
                    }
                }
            }

            &:hover {
                cursor: pointer;

                a {
                    font-weight: 700;
                }
            }

            a {
                color: color(base, DefaultFontColor);
                font-size: 1.4rem;
                transition: unset;
            }

            span {
                display: inline-block;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                transition: .5s cubic-bezier(1, 1.78, 0, 0.71);
                width: .3rem;
                z-index: -1;
            }

            i {
                text-align: center;
                width: 2rem;
            }
        }
    }
}
