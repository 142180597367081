footer {
    background-image: url("/area/web/dane/assets/images/Footer_BG.svg");
    padding-top: 20rem;
    margin-top: 4rem;
    @include breakpoint(small only) {
        padding-top: 15rem;
        background-size: 500%;
        background-position-x: -25rem;
    }

    .footer {
        background: linear-gradient(0deg, rgba(208,208,208,1) 0%, rgba(208,208,208,1) 80%, rgba(255,255,255,0) 100%);
        padding: 0 2rem;

        h5 {
            color: color(Dane, Black);
            font-size: 1.6rem;
            line-height: 3.2rem;
            font-weight: 900;
            text-transform: uppercase;
        }

        .footer__links {
            padding: 3rem 0;

            &.--columns {
                ul {
                    columns: 2;
                    -webkit-columns: 2;
                    -moz-columns: 2;
                }
            }
            &.--socials {
                ul {
                    display: flex;
                    gap: 1rem;
                }
            }

            ul a {
                @include transition();
                display: inline-flex;
                color: color(Dane, Black);
                font-size: 1.3rem;
                line-height: 2.5rem;
                background-repeat: no-repeat;
                background-position: left center;
                background-size: 0.7rem;

                @include hover() {
                    padding: 0 0 0 1.5rem;
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#{color(Dane, Red)}" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>');
                }

                &.fb, &.insta {
                    display: inline-block;
                    width: 2.5rem;
                    height: 2.5rem;
                    line-height: 3rem;
                    text-align: center;
                    background-color: color(Dane, Gold);
                    border-radius: 2.5rem;
                    @include hover() {
                        padding: 0;
                        background-image: none;
                        transform: scale(1.1);
                        cursor: pointer;
                    }
                }
                &.fb {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="8.75" viewBox="0 0 320 512"><path fill="%23ffffff" d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"></path></svg>');
                    background-position: center center;
                    background-size: auto;
                }
                &.insta {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" height="14" width="12.25" viewBox="0 0 448 512"><path fill="%23ffffff" d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"/></svg>');
                    background-position: center center;
                    background-size: auto;
                }
            }

        }

        .footer__logo {
            display: inline-flex;
            justify-content: flex-end;
            max-width: 18rem;

            a {
                display: block;
            }

            &.--gore-tex {
                max-width: 18rem;
                margin-right: 9rem;
                text-transform: uppercase;
                font-weight: 900;
                padding: 0 3rem;
                text-align: center;
                > span div {
                    margin: 0 -3rem;
                    > span {
                        display: block;
                    }
                }
            }
        }
    }

    .absolute__footer {
        padding: 2rem 0;
        background: color(Dane, Silver);

        > div {
            display: flex;
            flex-flow: column;
            align-items: center;
            @include breakpoint(medium) {
                flex-flow: row;
                justify-content: space-between;
            }

            a {
                color: color(Dane, Black);
                font-size: 1.4rem;
                line-height: 2.4rem;

                @include hover() {
                    text-decoration: underline;
                }
            }

            p {
                a:not(:last-child)::after {
                    content: '|';
                    display: inline-block;
                    margin: 0 .8rem;
                }
            }
        }
    }
}
