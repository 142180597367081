.catalog-product-page_body {
    .media__gallery {
        .product__media-container {
            .--thumbnails {
                order: 2;
            }
            .--images {
                order: 1;
            }
        }
    }
}
