.product__related {
    .catalog__category-list {
        .grid-x.grid-padding-x {
            margin-right: 0;

            @include breakpoint(medium) {
                margin-right: 0;
            }
        }
    }
    .swiper {
        max-width: 100%;
        //.swiper-slide {
        //    @include breakpoint(large) {
        //        width: 50%;
        //    }
        //}
    }
}
