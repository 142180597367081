.checkout-cart {
    .cart__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 1rem 0;
        gap: 3rem;

        @include breakpoint(medium) {
            margin: 3rem 0;
        }

        h1 {
            font-size: 2.4rem;
        }

        .--actions {
            display: flex;
            align-items: center;
            gap: 3rem;
        }
    }

    .cart__container {
        .--heading {
            span {
                font-size: 1.5rem;
                text-transform: uppercase;
                font-weight: 700;
                line-height: 4rem;
                display: block;

                &.--right {
                    text-align: right;
                }
            }
        }

        .--separator {
            width: initial;
            height: .1rem;
            background-color: color(Gray, Gray-300);
        }

        .image__holder {
            display: flex;
            padding: .5rem;

            @include breakpoint(small only) {
                padding: 1rem 0;
            }
        }

        .item__details {
            padding: 1rem 0;
            display: flex;
            flex-direction: column;
            align-items: baseline;

            @include breakpoint(medium) {
                padding: 2rem 0;
            }

            a {
                font-size: 1.6rem;
                color: color(base, DefaultFontColor);
                font-weight: 700;
            }

            @include breakpoint(small only) {
                > span {
                    font-size: 1.2rem;
                }
            }

            .item__comment {
                margin: 1.5rem 0 0;

                > button {
                    font-weight: 600;
                    text-decoration: underline;
                    cursor: pointer;
                    background-repeat: no-repeat;
                    background-size: 1.2rem;
                    background-position: right;
                    padding-right: 1.8rem;
                    font-size: 1.2rem;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");

                    &.--open {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                    }
                }

                > div {
                    transition: all .2s ease-in-out .2s;
                    overflow: hidden;

                    form {
                        display: flex;
                        gap: 1rem;
                        align-items: center;
                        margin: 1rem 0 0;

                        textarea {
                            resize: none;
                            min-height: 6rem;
                            min-width: 22rem;
                        }

                        .--actions {
                            display: flex;
                            align-items: center;
                            gap: 1rem;

                            .--success {
                                background-repeat: no-repeat;
                                width: 2rem;
                                height: 2rem;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3.5' stroke='#{color(base, Green)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 12.75 6 6 9-13.5' /%3E%3C/svg%3E%0A");
                            }
                        }
                    }
                }

                &.--active {
                    > span {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                    }

                    > div {
                        max-height: 30rem;
                    }
                }
            }
        }

        .item__delivery {
            padding: 1rem 0 0;

            @include breakpoint(medium) {
                padding: 2rem 0;
            }

            > span {
                display: inline-block;
                line-height: 4rem;
            }
        }

        .item__actions {
            display: flex;
            align-items: center;
            gap: 1rem;
            padding: 1rem 0 0;

            @include breakpoint(medium) {
                padding: 2rem 0;
            }

            @include breakpoint(small only) {
                justify-content: flex-end;

                form {
                    &:first-of-type {
                        order: 1;
                    }

                    &:last-of-type {
                        order: 0;
                    }
                }
            }

            form:first-of-type {
                width: 67%;
            }

            .product__qty {
                display: flex;

                input {
                    width: 9rem;
                    border-radius: .5rem 0 0 .5rem;
                    height: 3.8rem;
                    line-height: 3.8rem;
                }

                select {
                    width: 100%;
                    min-width: auto;
                }

                .--controls {
                    flex: 0 0 2rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;

                    button {
                        @include default-button();
                        line-height: 1.9rem;
                        width: 100%;
                        padding: 0;
                        justify-content: center;
                        align-items: center;
                        background-image: none;

                        &:first-of-type {
                            border-radius: 0 .5rem 0 0;
                        }

                        &:last-of-type {
                            border-radius: 0 0 .5rem 0;
                        }
                    }
                }
            }

            .--remove {
                @include transition();
                width: 2rem;
                height: 2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(Gray, Gray-500)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");

                @include hover() {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(Gray, Gray-700)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                }
            }
        }

        .price__holder {
            padding: 2rem 0;
            line-height: 4rem;
            display: flex;
            justify-content: flex-end;
            gap: .5rem;

            .price {
                font-size: 1.6rem;
            }

            .tax-indicator {
                font-size: 1.1rem;
                color: color(Gray, Gray-500);
            }
        }
    }

    .order__comment {
        .input__group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            label {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 1.5rem;
                line-height: 4rem;
            }

            input {
                width: 80%;
            }

            textarea {
                width: 100%;
                min-height: 8rem;
                resize: none;
            }
        }

        .--actions {
            margin: 2rem 0 0;
            display: flex;
            align-items: center;
            gap: 1rem;

            .--success {
                background-repeat: no-repeat;
                width: 2rem;
                height: 2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3.5' stroke='#{color(base, Green)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='m4.5 12.75 6 6 9-13.5' /%3E%3C/svg%3E%0A");
            }
        }
    }

    .totals__container {
        padding: 1rem 0;
        border-top: .2rem solid color(base, DefaultFontColor);

        @include breakpoint(small only) {
            margin: 2rem 0 0;
        }

        .cart__totals {
            > div {
                display: flex;
                justify-content: space-between;
                gap: 15rem;

                span {
                    font-size: 1.6rem;
                    display: inline-block;
                    line-height: 3.2rem;
                }

                &.--total span {
                    font-weight: 700;
                }
            }
        }

        .cart__actions {
            display: flex;
            align-items: center;
            margin: 2rem 0 0;

            .continue__shopping {
                @include transition();
                font-size: 1.4rem;
                background-repeat: no-repeat;
                background-size: 1.2rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
                background-position: left top .6rem;
                padding-left: 1.8rem;

                @include hover() {
                    color: color(base, PrimaryButtonHover);
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='#{color(base, PrimaryButtonHover)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
                }
            }

            .--button {
                margin-left: auto;
                min-width: 20rem;
                justify-content: center;
                &.--disabled{
                    pointer-events: none;
                }
            }
        }

        .cart__terms {
            margin: 2rem 0 0;
            display: flex;
            gap: 1rem;
            align-items: center;
            label{
                display:flex;
            }
            p {
                font-size: 1.2rem;
                line-height: 1.6rem;
                max-width: 40rem;

                a {
                    color: color(base, DefaultFontColor);
                    font-size: 1.2rem;
                    line-height: 1.6rem;
                    text-decoration: underline;
                }
            }
        }
    }
}

.checkout-cart-empty {
    h1 {
        margin: 3rem 0;
    }

    p {
        line-height: 2.6rem;
    }
}

.--secure {
    color: color(base, Green);
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, Green)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z' /%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position: center left;
    background-size: 1.6rem;
    padding-left: 2rem;

    @include breakpoint(small only) {
        font-size: 1.3rem;
    }
}
