.homepage__fullscreen-two-column--block-wrapper {
    height: 100%;

    > div {
        height: 100%;
        background-size: cover;

        @include breakpoint(small only) {
            .--content {
                margin: 0 0 2rem;

                *:not(h2) {
                    display: none;
                }

                h2 {
                    text-align: center;
                }
            }
        }
    }
}
