@mixin attributes() {
    > span {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 2rem;

        span {
            display: block;
            line-height: 2.4rem;
            font-size: 1.2rem;
            text-align: right;

            &:first-child {
                font-weight: 700;
                text-transform: uppercase;
            }
        }
    }
}

.product__configuration {
    width: 100%;
    margin: 2rem 0 0;

    .product__price-container {
        padding: .5rem 0;
        border-top: .1rem solid color(Gray, Gray-300);
        border-bottom: .1rem solid color(Gray, Gray-300);

        @include breakpoint(medium up) {
            padding: 1.5rem 0;
        }

        .--price {
            display: flex;
            gap: .5rem;
            font-weight: 700;
            font-size: 2.2rem;
            line-height: 2.4rem;

            > span {
                font-size: 2.2rem;
                line-height: 2.4rem;
            }
        }

        .--advice {
            display: block;
            margin: 1rem 0 0;
            font-size: 1.2rem;
            text-transform: uppercase;
        }

        .product__price-extra {
            display: flex;
            flex-wrap: wrap;
            gap: .5rem;
            align-items: center;
            margin: 1rem 0 0;
            font-size: 1.2rem;
            text-transform: uppercase;
            color: color(Gray, Gray-500);
        }
    }

    > .product__attributes {
        margin: 2.5rem 0 0;

        > div {
            @include attributes();
            @include breakpoint(medium) {
                max-width: 40%;
            }
        }
    }

    .option__attributes--container {
        display: grid;

        @include breakpoint(medium) {
            grid-template-columns: 60% 40%;
        }

        .product__option {
            @include breakpoint(medium) {
                padding: 0 2rem 0 0;
                display: flex;
                align-items: center;
                min-height: 10rem;
            }

            .product__configurable {
                display: flex;
                flex-flow: column;
                gap: 2rem;

                .product__configurable-option {
                    dl {
                        dt {
                            display: flex;
                            align-items: center;

                            &.color__block {
                                padding: 1rem 0 2rem;

                                .selected__color {
                                    margin-left: auto;

                                    > span {
                                        color: color(Gray, Gray-600);
                                    }
                                }
                            }
                        }

                        dd {
                            &.swatch__holder {
                                display: grid;
                                grid-template-columns: repeat(4, 1fr);
                                gap: 1rem;

                                label {
                                    cursor: pointer;
                                    aspect-ratio: 1;
                                    padding: .2rem;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;

                                    @include hover() {
                                        &:not(.--active) > span {
                                            border-color: color(Gray, Gray-300);
                                        }
                                    }

                                    &.--active {
                                        > span {
                                            border-color: color(base, DefaultFontColor);
                                        }
                                    }

                                    > span {
                                        @include transition();
                                        display: flex;
                                        position: relative;
                                        align-items: center;
                                        justify-content: center;
                                        border-bottom: .3rem solid transparent;

                                        input[type='radio'] {
                                            position: absolute;
                                            left: -999rem;
                                            text-indent: -999rem;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .product__attributes {
            > div {
                @include attributes();
                height: 100%;
                border-top: .1rem solid color(Gray, Gray-300);
                padding: 1rem 0;
                margin: 2rem 0 0;

                @include breakpoint(medium) {
                    padding: 1rem 0 1rem 2rem;
                    border-left: .1rem solid color(Gray, Gray-300);
                    border-top: none;
                    margin: 0;
                }
            }
        }
    }
}
