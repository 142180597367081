@font-face {
    font-family: 'Red Hat Display';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/dane/assets/fonts/RedHatDisplay-Regular.woff2') format('woff2'),
    url('/area/web/dane/assets/fonts/RedHatDisplay-Regular.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: 500;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/dane/assets/fonts/RedHatDisplay-Medium.woff2') format('woff2'),
    url('/area/web/dane/assets/fonts/RedHatDisplay-Medium.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: 700;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/dane/assets/fonts/RedHatDisplay-Bold.woff2') format('woff2'),
    url('/area/web/dane/assets/fonts/RedHatDisplay-Bold.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: 800;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/dane/assets/fonts/RedHatDisplay-ExtraBold.woff2') format('woff2'),
    url('/area/web/dane/assets/fonts/RedHatDisplay-ExtraBold.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: 900;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/dane/assets/fonts/RedHatDisplay-Black.woff2') format('woff2'),
    url('/area/web/dane/assets/fonts/RedHatDisplay-Black.woff') format('woff');
}

@font-face {
    font-family: 'Red Hat Display';
    font-weight: normal;
    font-style: italic;
    font-display: swap;
    src: url('/area/web/dane/assets/fonts/RedHatDisplay-Italic.woff2') format('woff2'),
    url('/area/web/dane/assets/fonts/RedHatDisplay-Italic.woff') format('woff');
}

@font-face {
    font-family: 'Font Awesome 5 Free Solid';
    font-weight: normal;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/dane/assets/fonts/FontAwesome5Free-Solid.woff2') format('woff2'),
    url('/area/web/dane/assets/fonts/FontAwesome5Free-Solid.woff') format('woff');
}

@font-face {
    font-family: 'Minion Pro';
    font-weight: 400;
    font-style: normal;
    font-display: swap;
    src: url('/area/web/dane/assets/fonts/MinionPro-Regular.woff2') format('woff2'),
    url('/area/web/dane/assets/fonts/MinionPro-Regular.woff') format('woff');
}

