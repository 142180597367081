.homepage__hero--block-wrapper {
    @include breakpoint(medium) {
        margin: 0 0 -2rem;
    }

    .blaze-slider {
        width: 100%;
    }

    .--block {
        display: block;
        position: relative;
        overflow: hidden;
        height: 100%;

        @include breakpoint(medium) {
            height: calc(100% - 2rem);
            margin: 0 0 2rem;
        }

        > span {
            transition: all .5s ease;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        @include breakpoint(medium) {
            &.wide {
                > span {
                    position: absolute;
                    background: rgba(48, 48, 48, .6);
                    bottom: 0;
                    left: 0;
                    right: 0;
                    height: 14.8rem;
                }
            }

            &.small {
                display: flex;
                flex-direction: column;
                height: calc(100% - 2rem);
                gap: 2rem;

                picture {
                    height: 100%;
                }

                > span {
                    background: color(base, DefaultFontColor);
                    flex: 0 0 14.8rem;
                }
            }
        }

        @include breakpoint(small only) {
            > span {
                position: absolute;
                background: rgba(48, 48, 48, .6);
                bottom: 0;
                left: 0;
                right: 0;
                padding: 3rem 0;
            }
        }

        > picture {
            overflow: hidden;

            > img {
                transition: all .5s ease;
                display: block;
                width: 100%;
                height: 100%;

                @include breakpoint(small only) {
                    object-fit: cover;
                }
            }
        }

        @include hover() {
            > picture > img {
                transform: scale(1.2);
                filter: grayscale(75%);
            }

            &.wide > span {
                background: rgba(0, 0, 0, .6);
            }

            &.small > span {
                background: color(base, Black);
            }
        }
    }
}
