input[type='text'],
input[type='search'],
input[type='date'],
input[type='tel'],
input[type='email'],
input[type='number'],
input[type='password'],
select,
textarea {
    &:not(.vs__search) {

        @include transition;
        @include border-radius();
        background-color: color(base, White);
        border: 0.1rem solid color(Gray, Gray-300);
        font-size: 1.4rem;
        height: 3.4rem;
        line-height: 3.4rem;
        padding: 0 1em;

        @include breakpoint(medium) {
            font-size: 1.6rem;
            padding: 0 1.5rem;
            height: 4rem;
            line-height: 4rem;
        }

        &[type='date'] {
            align-items: center;
        }

        &:disabled {
            background-color: color(gray, Gray-50);
            cursor: not-allowed;
        }

        &.failed {
            border-color: color(alert, Error);
        }

        &.success {
            border-color: color(alert, Success);
        }
    }
}

input[type='checkbox'] {
    @include border-radius(0.3rem);
    align-items: center;
    background: color(base, White);
    border: 0.1rem solid color(gray, Gray-300);
    display: flex;
    height: 1.8rem;
    justify-content: center;
    width: 1.8rem;
    position: relative;

    &:checked {
        background: color(base, Primary);
        border: none;

        &::after {
            @include border-radius(0.3rem);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='4' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
            content: '';
            display: block;
            height: 1.2rem;
            width: 1.2rem;
            position: absolute;
        }
    }
}

button.checkbox__button {
    position: relative;
    display: flex;
    align-items: center;
    gap: .5rem;

    &::before {
        background: color(base, White);
        border: .1rem solid color(Gray, Gray-300);
        border-radius: .3rem;
        content: '';
        display: inline-block;
        height: 2rem;
        margin: .1rem .5rem .1rem .1rem;
        vertical-align: top;
        width: 2rem;
    }

    &.--active::before {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
        border: .2rem solid color(base, DefaultFontColor);
    }
}

input[type='radio'] {
    -webkit-appearance: radio;
    resize: none;
}

.field__container {
    margin: 0 0 2rem;

    &.--flex {
        align-items: center;
        display: flex;
        gap: 1rem;

        &:has(> .field__container) {
            gap: 2rem;
        }

        > *:not(input) {
            flex: 1;
            margin: 0;
        }
    }

    &._flex,
    &.flex {
        display: flex;
        gap: 1rem;

        label {
            cursor: pointer;
            @include hover {
                text-decoration: underline;
            }
        }
    }

    label {
        align-items: self-end;
        display: flex;
        font-size: 1.3rem;
        font-weight: 600;
        gap: 1rem;
        margin: 0 0 0.7rem;
    }

    input[type='text'],
    input[type='search'],
    input[type='date'],
    input[type='tel'],
    input[type='email'],
    input[type='number'],
    input[type='password'] {
        background-position: right 1rem center;
        background-repeat: no-repeat;
        background-size: 2rem;
        width: 100%;
    }

    select {
        width: 100%;

        option {
            min-width: 15rem;
        }
    }

    &.--check {
        label {
            cursor: pointer;
        }
    }

    .validator-err {
        color: color(alert, Error);
        font-size: 1.2rem;
        margin: 0.3rem 0 0;

        &:not(:first-of-type) {
            display: none;
        }
    }

    &:has(> .validator-err) {
        input {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.8' stroke='#{color(alert, Error)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 9v3.75m9-.75a9 9 0 11-18 0 9 9 0 0118 0zm-9 3.75h.008v.008H12v-.008z' /%3E%3C/svg%3E%0A");
            border-color: color(alert, Error);
        }

        select {
            border-color: color(alert, Error);
        }
    }

    .strength__badge {
        background: color(alert-backgrounds, Error);
        border: 0.1rem solid color(grey, Gray-200);
        border-top: none;
        color: color(alert, Error);
        display: none;
        font-size: 1.2rem;
        line-height: 1.4rem;
        padding: 0.5rem 1rem;

        &.--medium {
            background: color(alert-backgrounds, Warning);
            color: color(alert, Warning);
        }

        &.--strong {
            background: color(alert-backgrounds, Success);
            color: color(alert, Success);
        }
    }
}

select {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
    background-position: right 1.5rem center;
    background-repeat: no-repeat;
    background-size: 2rem;
    min-width: 20rem;

    &.--transparent {
        border: none;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
        background-position: right center;
        padding: 0 2.5rem 0 1rem;
    }
}

.field-error {
    color: color(alert, Error);
    display: block;
    font-size: 1.3rem;
    margin: 0.5rem 0 0;
}

.field__actions {
    align-items: center;
    display: flex;
    gap: 2rem;

    a {
        font-size: 1.2rem;
        line-height: 3rem;
        text-align: right;
    }

    button {
        @include default-button;
    }
}

.--button {
    @include default-button;
}

.checkout__address-autocomplete_result {
    width: fit-content;

    .resolved__address {
        background: color(gray, Gray-100);
        font-size: 1.4rem;
        margin: 0 0 2rem;
        padding: 1rem;

        > span {
            display: block;
            font-weight: 700;
            margin: 0 0 0.5rem;
        }

        address {
            line-height: 2rem;
        }
    }
}


.v-select {
    width: 100%;

    .vs__spinner {
        animation: unset;
        width: 2.1rem;
        height: 2rem;
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-image: url("data:image/svg+xml,%0A%3Csvg width='135' height='140' viewBox='0 0 135 140' xmlns='http://www.w3.org/2000/svg' fill='%23303030'%3E%3Crect y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.5s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.5s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='30' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.25s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.25s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='60' width='15' height='140' rx='6'%3E%3Canimate attributeName='height' begin='0s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='90' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.25s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.25s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3Crect x='120' y='10' width='15' height='120' rx='6'%3E%3Canimate attributeName='height' begin='0.5s' dur='1s' values='120;110;100;90;80;70;60;50;40;140;120' calcMode='linear' repeatCount='indefinite' /%3E%3Canimate attributeName='y' begin='0.5s' dur='1s' values='10;15;20;25;30;35;40;45;50;0;10' calcMode='linear' repeatCount='indefinite' /%3E%3C/rect%3E%3C/svg%3E");
        border: none;
        border-radius: 0;
    }

    input, input:focus {
        cursor: pointer;
        font-size: 1.6rem;
        margin: 0;
        line-height: 3.6rem;
    }

    .vs__selected-options {
        cursor: pointer;
        padding: 0 .8rem;
        gap: 1rem;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow: hidden;
        max-width: 275px;

        .span, .vs__selected {
            white-space: nowrap;
        }
    }

    .vs__selected {
        margin: 0;
    }

    .vs__dropdown-toggle {
        padding: 0;
    }

    .vs__actions {
        padding: 0 1rem;
        margin-left: auto;
    }

    .vs__dropdown-option {
        line-height: 3.2rem;

        &.vs__dropdown-option--highlight {
            background-color: color(base, PrimaryButton);
        }
    }

    .dropdown__option {
        display: flex;
        gap: .8rem;
        align-items: center;

        > span {
            display: block;

            &.--pre {
                display: flex;
                gap: .1rem;
                align-items: center;

                > span {
                    display: flex;
                    gap: .1rem;
                    align-items: center;

                    i {
                        width: .8rem;
                        height: 1.5rem;

                        &.--indent {
                            width: 1.5rem;
                            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M.2 468.9C2.7 493.1 23.1 512 48 512l96 0 320 0c26.5 0 48-21.5 48-48l0-96c0-26.5-21.5-48-48-48l-48 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-64 0 0 80c0 8.8-7.2 16-16 16s-16-7.2-16-16l0-80-80 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l80 0 0-64-80 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l80 0 0-64-80 0c-8.8 0-16-7.2-16-16s7.2-16 16-16l80 0 0-48c0-26.5-21.5-48-48-48L48 0C21.5 0 0 21.5 0 48L0 368l0 96c0 1.7 .1 3.3 .2 4.9z'/%3E%3C/svg%3E");
                            background-size: .8rem;
                            background-position: right .3rem top .4rem;
                        }
                    }
                }
            }

            i {
                display: block;
                width: 1.5rem;
                height: 1.5rem;
                background-repeat: no-repeat;
                background-size: contain;
                background-position: center;

                &.--customer {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='14' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z'/%3E%3C/svg%3E");
                }

                &.--sales-force {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='14' viewBox='0 0 448 512'%3E%3C!--!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M96 128a128 128 0 1 0 256 0A128 128 0 1 0 96 128zm94.5 200.2l18.6 31L175.8 483.1l-36-146.9c-2-8.1-9.8-13.4-17.9-11.3C51.9 342.4 0 405.8 0 481.3c0 17 13.8 30.7 30.7 30.7H162.5c0 0 0 0 .1 0H168 280h5.5c0 0 0 0 .1 0H417.3c17 0 30.7-13.8 30.7-30.7c0-75.5-51.9-138.9-121.9-156.4c-8.1-2-15.9 3.3-17.9 11.3l-36 146.9L238.9 359.2l18.6-31c6.4-10.7-1.3-24.2-13.7-24.2H224 204.3c-12.4 0-20.1 13.6-13.7 24.2z'/%3E%3C/svg%3E");
                }

                &.--disabled {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{color(alert, Error)}' height='16' width='16' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M367.2 412.5L99.5 144.8C77.1 176.1 64 214.5 64 256c0 106 86 192 192 192c41.5 0 79.9-13.1 111.2-35.5zm45.3-45.3C434.9 335.9 448 297.5 448 256c0-106-86-192-192-192c-41.5 0-79.9 13.1-111.2 35.5L412.5 367.2zM0 256a256 256 0 1 1 512 0A256 256 0 1 1 0 256z'/%3E%3C/svg%3E");
                }
            }
        }
    }
}

.vdp-datepicker {
    font-size: 1.6rem;

    .vdp-datepicker__calendar-button {
        position: absolute;
        top: .1rem;
        bottom: .1rem;
        left: .1rem;
        width: 4rem;
        text-align: center;
        background-color: #f7f7f7;
        border-radius: .3rem 0 0 .3rem;
        border-right: .1rem solid color(Gray, Gray-300);
        line-height: 3.8rem;
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5m-9-6h.008v.008H12v-.008zM12 15h.008v.008H12V15zm0 2.25h.008v.008H12v-.008zM9.75 15h.008v.008H9.75V15zm0 2.25h.008v.008H9.75v-.008zM7.5 15h.008v.008H7.5V15zm0 2.25h.008v.008H7.5v-.008zm6.75-4.5h.008v.008h-.008v-.008zm0 2.25h.008v.008h-.008V15zm0 2.25h.008v.008h-.008v-.008zm2.25-4.5h.008v.008H16.5v-.008zm0 2.25h.008v.008H16.5V15z' /%3E%3C/svg%3E%0A");
        background-size: 2rem;
        background-repeat: no-repeat;
        background-position: center;

        i {
            display: none;
        }
    }

    input:not(.vs__search) {
        padding-left: 4.5rem;

        &[readonly="readonly"]:not([disabled]) {
            background: color(base, White);
            cursor: pointer;
        }
    }
}
