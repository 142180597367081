.service__portal-container {

    .dashboard__block {
        @include breakpoint(medium) {
            > div {
                max-width: 40%;
            }
        }
    }

    .service__portal {
        display: flex;
        gap: 4rem;

        .service__search {
            min-width: 40rem;

            .--form {
                @include border-radius();
                background: color(Gray, Gray-25);
                border: .1rem solid color(Gray, Gray-200);
                padding: 1rem;
                display: flex;
                gap: 1rem;
                flex-flow: column;

                .--sep {
                    display: block;
                    text-align: center;
                }

                .input__group {
                    @include border-radius();
                    display: flex;
                    gap: 1rem;
                    flex-flow: column;
                    padding: 1rem;
                    background: color(base, White);
                    border: .1rem solid color(Gray, Gray-200);
                }

                .input__box {
                    display: flex;
                    gap: 1rem;
                    align-items: center;

                    label {
                        font-size: 1.4rem;
                        min-width: 15rem;

                        em {
                            color: color(alert, Error);
                        }
                    }

                    .input__holder {
                        flex: 1;

                        input {
                            line-height: 3.4rem;
                            height: 3.4rem;
                            font-size: 1.4rem;

                            &:not([type="search"]) {
                                width: 100%;
                            }
                        }

                        .vs__selected {
                            font-size: 1.4rem;
                        }
                    }
                }

                .--actions {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    gap: 1rem;

                    button {
                        padding: 0 3rem;
                    }
                }
            }
        }

        .search__results {
            flex: 1;

            .quick__buy__sorter {
                margin: 0 0 2rem;
                align-items: center;
                justify-content: space-between;
            }

            .mobile__scrollable {
                td {
                    vertical-align: middle;
                }
            }

            .--actions {
                margin: 2rem 0 0;
                display: flex;
                justify-content: center;
                gap: 1rem;
                align-items: center;
            }
        }

        .service__results {
            flex: 1;

            .mobile__scrollable {
                th, td {
                    &.--right {
                        text-align: right;
                    }
                }

                td {
                    vertical-align: middle;
                }

                tbody {
                    tr {
                        &:first-of-type {
                            cursor: pointer;
                        }

                        td {
                            button.--toggle {
                                width: 1.8rem;
                                height: 1.8rem;
                                background-repeat: no-repeat;
                                background-position: center;
                                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                            }

                            i {
                                display: block;
                                width: 1.8rem;
                                height: 1.8rem;
                                margin-left: auto;
                                background-repeat: no-repeat;
                                background-position: center;

                                &.--success {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z' /%3E%3C/svg%3E%0A");
                                }

                                &.--disabled {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M18.364 18.364A9 9 0 005.636 5.636m12.728 12.728A9 9 0 015.636 5.636m12.728 12.728L5.636 5.636' /%3E%3C/svg%3E%0A");
                                }
                            }

                            .service__settings {
                                @include border-radius();
                                background: #fafafa;
                                border: .1rem solid #eaeaea;
                                padding: .5rem .2rem;

                                label:not(.--button) {
                                    display: block;
                                    margin: .5rem 0 .2rem;

                                    em {
                                        font-size: 1.2rem;
                                        font-style: normal;
                                        color: color(alert, Error);
                                    }
                                }

                                .input__box {
                                    > input, > select, > textarea {
                                        width: 100%;
                                        min-width: auto;
                                        font-size: 1.4rem;
                                        line-height: 3rem;
                                    }

                                    &.--attachments {
                                        display: flex;
                                        flex-wrap: wrap;
                                        gap: 1rem;

                                        span {
                                            display: block;

                                            label {
                                                display: flex;
                                                gap: 1rem;
                                            }
                                        }
                                    }

                                    + .--error {
                                        display: block;
                                        margin: .3rem 0 0;
                                        color: color(alert, Error);
                                    }
                                }

                                textarea {
                                    min-height: 12rem;
                                    resize: none;
                                }

                                .--actions {
                                    display: flex;
                                    justify-content: flex-end;
                                    margin: 2rem 0 0;

                                    button {
                                        background-repeat: no-repeat;
                                        background-position: left 1rem center;
                                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                                        background-size: 1.6rem;
                                        padding-left: 3.2rem;
                                    }
                                }
                            }
                        }
                    }

                    &.--active {
                        tr {
                            &:first-of-type {
                                border: none;
                            }

                            td {
                                button.--toggle {
                                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 15.75l7.5-7.5 7.5 7.5' /%3E%3C/svg%3E%0A");
                                }
                            }
                        }
                    }
                }
            }

            form > .--actions {
                margin: 2.8rem 0 0;
                display: flex;
                gap: 2rem;

                button {
                    background-repeat: no-repeat;
                    background-position: left 1rem center;
                    background-size: 1.6rem;
                    padding-left: 3.5rem;

                    &.--submit {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='16' fill='#{color(base, White)}' viewBox='0 0 512 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z'/%3E%3C/svg%3E");
                    }

                    &.--xlsx {
                        background-image: url("data:image/svg+xml,%3Csvg fill='#{color(base, White)}' xmlns='http://www.w3.org/2000/svg' height='16' width='12' viewBox='0 0 384 512'%3E%3C!--!Font Awesome Free 6.5.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2023 Fonticons, Inc.--%3E%3Cpath d='M48 448V64c0-8.8 7.2-16 16-16H224v80c0 17.7 14.3 32 32 32h80V448c0 8.8-7.2 16-16 16H64c-8.8 0-16-7.2-16-16zM64 0C28.7 0 0 28.7 0 64V448c0 35.3 28.7 64 64 64H320c35.3 0 64-28.7 64-64V154.5c0-17-6.7-33.3-18.7-45.3L274.7 18.7C262.7 6.7 246.5 0 229.5 0H64zm90.9 233.3c-8.1-10.5-23.2-12.3-33.7-4.2s-12.3 23.2-4.2 33.7L161.6 320l-44.5 57.3c-8.1 10.5-6.3 25.5 4.2 33.7s25.5 6.3 33.7-4.2L192 359.1l37.1 47.6c8.1 10.5 23.2 12.3 33.7 4.2s12.3-23.2 4.2-33.7L222.4 320l44.5-57.3c8.1-10.5 6.3-25.5-4.2-33.7s-25.5-6.3-33.7 4.2L192 280.9l-37.1-47.6z'/%3E%3C/svg%3E");
                    }
                }
            }
        }
    }
}
