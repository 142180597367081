/* CSS Reset */
* {
    margin: 0;
    padding: 0;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -webkit-font-smoothing: antialiased;
    -ms-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -o-font-smoothing: antialiased;
    font-smoothing: antialiased;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);
    -moz-osx-font-smoothing: grayscale;
}

html {
    font-family: sans-serif; /* 1 */
    -ms-text-size-adjust: 100%; /* 2 */
    -webkit-text-size-adjust: 100%; /* 2 */
    color: color(base, DefaultFontColor);
}

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ol ol, ul, ul ul, ol ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video, button {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    vertical-align: baseline;
    outline: none;
    font-family: $default-font;
}

article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}

body {
    line-height: normal;
    background: color(base, White);
}

ol, ul {
    list-style: none;
}

blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after, q:before, q:after {
    content: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

select {
    outline: none;
}

img {
    display: block;
    outline: none;
    border-style: none;
}

a {
    transition: color 0.3s ease, background-color 0.3s ease;
    background-color: transparent; /* 1 */
    -webkit-text-decoration-skip: objects; /* 2 */
    text-decoration: none;
    color: color(base, PrimaryButton);
    outline: none;
}

q, p {
    font-size: 0.875rem;
    color: color(base, DefaultFontColor);
    line-height: 22px;
}

strong {
    font-weight: bold;
}

svg:not(:root) {
    overflow: hidden;
}

.select2-hidden-custom {
    position: absolute;
    left: -99999px;
}

span, li {
    vertical-align: top;
}

input[type="text"], input[type="password"], textarea, select {
    outline: none;
}

input:focus {
    background: #fff;
}

input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
}

select {
    margin: 0;
    padding: 0 35px 0 15px;
}

caption {
    text-align: left;
}

button, input {
    transition: background-color 0.3s ease, border-color 0.3s ease;
}

button:-moz-focusring, [type="button"]:-moz-focusring, [type="reset"]:-moz-focusring, [type="submit"]:-moz-focusring {
    outline: none;
}

input[type="checkbox"], input[type="radio"] {
    margin: 0;
    padding: 0;
}

section {
    width: 100%;
}

:-moz-placeholder {
    color: #333 !important;
}

::-moz-placeholder {
    color: #333 !important;
}

/* Table reset */
table {
    font-size: 1.6rem;
    th {
        padding: 10px 5px;
    }
    tbody {
        tr {
            border-bottom: 1px solid color(gray, Gray-300);
            background: none;
            &:nth-child(even) {
                border-bottom: 1px solid color(gray, Gray-300);
                background: none;
            }
        }
        td {
            line-height: 21px;
            padding: 10px 5px;
        }
    }
    tfoot {
        background: none;
        border-top: 1px solid color(gray, Gray-500);
        th, td {
            text-align: right;
        }
    }
}

/* Extra margin for medium down */
.grid-x {
    @include breakpoint(medium down) {
        margin-left: 10px;
        margin-right: 10px;
    }
    @include breakpoint(small down) {
        margin-left: 5px;
        margin-right: 5px;
    }
    .grid-x {
        margin-left: 0;
        margin-right: 0;
    }
}

.grid-x-no-margin {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
    padding-right: 0;
}

.sticky {
    z-index: 10;
}

.sticky.is-stuck {
    z-index: 10;
    &#stickyFilter {
        z-index: 9;
    }
}
