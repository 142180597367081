.catalog-product-page_body {
    .media__gallery {
        height: 100%;

        @include breakpoint(medium) {
            margin-top: -9rem;
            margin-bottom: 4rem;
        }

        .media__placeholder {
            height: 100%;

            span:first-of-type, span:last-of-type {
                flex: 1;
                aspect-ratio: initial;

                img {
                    margin: 0 auto;
                }
            }
        }

        .product__media-container {
            display: block !important;
            max-height: 100%;
        }
    }

    .swiper__pagination {

        .swiper-pagination-bullet {
            background: color(Gray, Gray-300);
            opacity: 1;
            width: 1.4rem;
            height: 1.4rem;

            &.swiper-pagination-bullet-active {
                background: color(Gray, Gray-700);
            }
        }
    }
}
