.checkout-cart {
    h1 {
        display: inline-block;

        &:after {
            content: '';
            display: block;
            @include rev-counter();
            margin-bottom: 1.2rem;
        }

        &:hover {
            &:after {
                @include rev-counter-hover();
            }
        }
    }

    .cart__container {
        .item__actions {
            .product__qty {
                .--controls {
                    margin-left: -1.6rem;

                    button {
                        display: flex;
                        border: none;
                        font-style: normal;
                        margin-right: 0;
                        text-align: center;

                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .totals__container {
        .cart__actions {
            .--button {
                min-width: 17rem;
            }

            a.continue__shopping {
                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2.5' stroke='%23595a5c' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5 8.25 12l7.5-7.5'/%3E%3C/svg%3E");
            }
        }
    }

    &-empty {

        h1 {
            display: inline-block;

            &:after {
                content: '';
                display: block;
                @include rev-counter;
                margin-bottom: 1.2rem;
            }

            &:hover {
                &:after {
                    @include rev-counter-hover();
                }
            }
        }
    }
}

.checkout-checkout_body {
    header {
        max-width: 100%;
        justify-content: space-around;
        border-color: color(Dane, Mesh);

        .header__logo {
            visibility: hidden;
        }

        h1 {
            color: color(Dane, White);
        }
    }

    .checkout-checkout {
        .progress-bar {
            > span {
                &.--active {
                    > span {
                        > span {
                            color: color(Dane, White);
                        }
                    }
                }

                &.--complete {
                    > span {
                        > span {
                            background-color: color(Dane, Mesh);
                        }

                        &::before {
                            border-color: color(Dane, Mesh) transparent;
                        }

                        &::after {
                            border-color: transparent color(Dane, Mesh);
                        }
                    }
                }
            }
        }

        h2 {
            display: inline-block;

            &::after {
                content: '';
                display: block;
                @include rev-counter();
                margin-bottom: 1.2rem;
            }

            &:hover {
                &::after {
                    @include rev-counter-hover();
                }
            }
        }

        .--right {
            .coupon-code__container .coupon-code__form .form-holder button {
                line-height: initial;
            }

            .shipping__information > button {
                color: color(base, DefaultFontColor);
            }
        }

        .checkout__summary {
            a {
                color: color(base, DefaultFontColor);
            }
        }
    }
}
