.off-canvas {
    &.position-right {
        height: 100%;
        overflow-y: auto;
        right: 0;
        top: 0;
        width: 25rem;

        &:not(.is-open) {
            transform: translateX(25rem);
        }
    }

    .modal__header {
        align-items: center;
        border-bottom: .1rem solid color(gray, Gray-300);
        display: flex;
        height: 4.2rem;
        flex: 0 0 4.2rem;
        padding: 0 1rem;

        > button {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, DefaultFontColor)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            height: 2.4rem;
            margin-left: auto;
            position: initial;
            width: 2.4rem;

            > span {
                display: block;
                height: 100%;
                width: 100%;
            }
        }
    }
}

.main__navigation {
    > ul {
        border-top: .5rem solid color(base, DefaultFontColor);
        display: flex;
        flex-wrap: wrap;
        gap: clamp(1rem, 0.98rem + 2.041vw, 4rem);
        row-gap: 0;

        > li {
            line-height: 4rem;

            > a, > span {
                color: color(base, DefaultFontColor);
                cursor: pointer;
                font-size: clamp(1.2rem, 0.996rem + 0.408vw, 1.6rem);
                font-weight: 700;
                position: relative;
                text-transform: uppercase;

                &::after,
                &::before {
                    border-left: .7rem solid transparent;
                    border-right: .7rem solid transparent;
                    content: '';
                    display: none;
                    height: 0;
                    left: 0;
                    margin-left: auto;
                    margin-right: auto;
                    position: absolute;
                    right: 0;
                    width: 0;
                }

                &::after {
                    border-top: .5rem solid color(base, DefaultFontColor);
                    top: -.8rem;
                }

                &::before {
                    border-top: .5rem solid color(base, White);
                    top: -1.4rem;
                }
            }

            > .--dropdown {
                background: rgba(255, 255, 255, .98);
                border-bottom: .1rem solid #f1f1f1;
                display: none;
                left: 0;
                padding: 1rem 0;
                position: absolute;
                right: 0;

                > div {
                    > .grid-container {
                        display: grid;
                        grid-template-columns: repeat(5, 1fr);
                        grid-gap: 1rem;

                        .column > span, .hide-title > span {
                            display: none;
                        }

                        .--group, > ul {
                            li {
                                line-height: 2.8rem;
                            }
                            a {
                                font-size: 1.4rem;
                                line-height: 2.8rem;
                                color: color(base, DefaultFontColor);

                                @include hover() {
                                    font-weight: 600;
                                }
                            }

                            .navigation__solar > a, > a {
                                font-weight: 700;
                                font-size: 1.6rem;

                                @include hover() {
                                    font-weight: 700;
                                }
                            }

                            .navigation__group {
                                > ul:not(:last-of-type) {
                                    margin: 0 0 2rem;
                                }

                                > a {
                                    font-weight: 700;
                                    font-size: 1.6rem;

                                    @include hover() {
                                        font-weight: 700;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &.--active {
                > a, > span {
                    color: color(base, PrimaryButton);

                    &::after,
                    &::before {
                        display: block;
                    }
                }

                > .--dropdown {
                    display: block;
                }
            }
        }
    }
}
