.catalog-product-page_body {
    .product__specifications {
        margin: 0;
        padding: 0;

        h3 {
            display: none;
        }


        .spec__list {
            background: none;
            padding: 0;
            min-height: auto;

            &.--technical {
                background: none;
            }

            h4 {
                font-style: italic;
                display: inline-block;

                &::after {
                    content: '';
                    display: block;
                    @include rev-counter();
                    margin-bottom: 1.2rem;
                }
            }

            @include hover() {
                h4::after {
                    @include rev-counter-hover();
                }
            }

            .attribute {
                padding-left: 4.8rem;
                display: flex;
                flex-direction: row;
            }

            .--divider {
                display: none;
            }

            .spec__collapse-trigger {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#{color(Dane, Mesh)}"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>');
                background-repeat: no-repeat;
                background-position: 0 0;
                background-size: 2rem;
                padding-left: 3rem;
                cursor: pointer;
                font-weight: 900;
                font-style: normal;
                color: color(Dane, Mesh);

                &.open {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" fill="#{color(Dane, Mesh)}" ><path d="M182.6 137.4c-12.5-12.5-32.8-12.5-45.3 0l-128 128c-9.2 9.2-11.9 22.9-6.9 34.9s16.6 19.8 29.6 19.8l256 0c12.9 0 24.6-7.8 29.6-19.8s2.2-25.7-6.9-34.9l-128-128z"/></svg>');
                    background-position: 0 .3rem;
                }
            }

            .spec__collapse-target {
                padding-left: 3rem;
                max-height: 0;
                opacity: 0;
                overflow: hidden;
                transition: max-height 0.5s ease, opacity 0.5s ease;
                display: flex;
                flex-wrap: wrap;

                &.open {
                    max-height: 150rem;
                    opacity: 1;
                }
            }

            .--spec {
                width: 50%;
                padding-right: 2rem;
                @include breakpoint(small only) {
                    width: 100%;
                    padding-right: 0;
                }

                p {
                    padding: 0;
                    display: block;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;

                    > button {
                        @include transition();
                        position: static;
                        background: color(base, PrimaryButton);
                        width: 1.7rem;
                        height: 1.7rem;
                        border-radius: .5rem;
                        display: inline-flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: 900;
                        margin-left: .5rem;
                        border: .2rem solid transparent;
                        font-size: 1.4rem;
                        line-height: .7;

                        @include hover() {
                            border-color: color(base, Black);
                        }
                    }
                }
            }
        }
    }
}
