.pagination {
    flex: 1;
    display: flex;
    justify-content: center;

    ul {
        display: flex;
        gap: .4rem;

        li {
            a, span {
                @include transition;
                @include border-radius();
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 3.2rem;
                width: 3.2rem;
                height: 3.2rem;
                font-size: 1.6rem;
                background-size: 1.6rem;
                background-repeat: no-repeat;
                background-position: center;
                color: color(base, PrimaryButton);

                @include hover {
                    &::before {
                        content: '';
                        display: block;
                        height: .2rem;
                        position: absolute;
                        background: color(base, DefaultFontColor);
                        bottom: .2rem;
                        left: 1rem;
                        right: 1rem;
                    }
                }

                &.--current {
                    color: color(base, DefaultFontColor);

                    &::before {
                        content: '';
                        display: block;
                        height: .2rem;
                        position: absolute;
                        background: color(base, DefaultFontColor);
                        bottom: .2rem;
                        left: 1rem;
                        right: 1rem;
                    }
                }

                &.--first {
                    background-image: url("data:image/svg+xml,%3Csvg class='w-6 h-6 text-gray-800 dark:text-white' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 10'%3E%3Cpath stroke='#{color(base, White)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 1 1 5l4 4m6-8L7 5l4 4'/%3E%3C/svg%3E");
                }

                &.--prev {
                    background-image: url("data:image/svg+xml,%3Csvg class='w-6 h-6 text-gray-800 dark:text-white' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 6 10'%3E%3Cpath stroke='#{color(base, White)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 1 1 5l4 4'/%3E%3C/svg%3E");
                    background-size: .8rem;
                }

                &.--next {
                    background-image: url("data:image/svg+xml,%3Csvg class='w-6 h-6 text-gray-800 dark:text-white' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 6 10'%3E%3Cpath stroke='#{color(base, White)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m1 9 4-4-4-4'/%3E%3C/svg%3E");
                    background-size: .8rem;
                }

                &.--last {
                    background-image: url("data:image/svg+xml,%3Csvg class='w-6 h-6 text-gray-800 dark:text-white' aria-hidden='true' xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 12 10'%3E%3Cpath stroke='#{color(base, White)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m7 9 4-4-4-4M1 9l4-4-4-4'/%3E%3C/svg%3E");
                }

                &.--bg {
                    background-color: color(base, PrimaryButton);

                    @include hover() {
                        background-color: color(base, PrimaryButtonHover);
                        &::before {
                            display: none;
                        }
                    }
                }
            }
        }
    }
}

.swiper__button {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(Gray, Gray-500)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 2.2rem;
    width: 1.8rem;

    &.--prev {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(Gray, Gray-500)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
    }

    &.swiper-button-disabled {
        visibility: hidden;
    }

    @include hover() {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");

        &.--prev {
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M15.75 19.5L8.25 12l7.5-7.5' /%3E%3C/svg%3E%0A");
        }
    }
}
