.homepage__content--block-wrapper {
    h2 {
        text-transform: uppercase;
        font-size: clamp(5.1rem, 5vw, 8rem);
        line-height: 1;
        font-weight: 900;
        font-style: italic;
    }

    h3 {
        text-transform: uppercase;
        font-size: clamp(2.8rem, 5vw, 4rem);
        line-height: 1;
        font-weight: 500;
    }

    h4 {
        text-transform: uppercase;
        font-size: clamp(2rem, 5vw, 3rem);
        line-height: 1;
        font-weight: normal;
    }

    .image__block {
        position: relative;

        h2 {
            position: absolute;
            z-index: 2;
            color: color(base, White);
            margin: 0;

            &.a-left {
                top: 5%;
                left: 5%;
            }

            &.a-center {
                top: 5%;
                right: 50%;
                transform: translateX(50%);
            }

            &.a-right {
                top: 5%;
                right: 5%;
            }
        }
    }

    .--content {
        @include breakpoint(medium) {
            padding-right: 30%;
            &.--right {
                padding-left: 30%;
                padding-right: 0;
            }
        }

        .--header {
            width: fit-content;
            display: flex;
            flex-flow: column;
            gap: 1rem;

            .content__separator {
                height: 15px;
                display: block;
                background: repeating-linear-gradient(-45deg, #fff, #fff 4px, #c6c6c6 5px, #c6c6c6 8px);
                @include breakpoint(small only) {
                    background: repeating-linear-gradient(-45deg, #fff, #fff 3px, #c6c6c6 4px, #c6c6c6 6px);
                    height: 10px;
                }

                &.--alternative {
                    background: repeating-linear-gradient(-45deg, transparent, transparent 4px, color(base, PrimaryButton) 5px, color(base, PrimaryButton) 8px);
                    @include breakpoint(small only) {
                        background: repeating-linear-gradient(-45deg, transparent, transparent 3px, color(base, PrimaryButton) 4px, color(base, PrimaryButton) 6px);
                    }
                }
            }
        }

        p {
            font-size: clamp(1.6rem, 4vw, 2rem);
            line-height: 1.5;
            margin: 1.5rem 0 2rem;

            @include breakpoint(medium) {
                margin: 3rem 0 0;
            }
        }
    }

    .--media {
        iframe {
            width: 100%;
            height: 100%;
        }

        @include breakpoint(medium) {
            img, > div {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            iframe {
                height: 532px;
            }
        }
    }

    .--in-view {
        img {
            opacity: 1;
            transform: scale(1);
        }
    }

    &.--faq {
        padding-top: 1rem;
        padding-bottom: 1rem;

        .faq__list {
            details {
                summary {
                    border-bottom: .1rem solid color(Gray, Gray-300);
                    cursor: pointer;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    gap: .8rem;
                    padding: 1rem 2.4rem 1rem 0;
                    font-weight: 700;
                    font-style: italic;
                    text-transform: uppercase;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_471_40655' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect width='24' height='24' fill='#{color(Gray, Gray-300)}'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_471_40655)'%3E%3Cpath d='M12 15.375L6 9.37498L7.4 7.97498L12 12.575L16.6 7.97498L18 9.37498L12 15.375Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                    background-repeat: no-repeat;
                    background-position: right 1rem top 1rem;
                    color: color(Gray, Gray-600);

                    @include breakpoint(medium) {
                        background-position: right top 1rem;
                    }

                    @include hover() {
                        &::after {
                            background-image: url("data:image/svg+xml,%3Csvg width='23' height='20' viewBox='0 0 23 20' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M16.0806 0H6.91938C6.08547 0 5.31029 0.43441 4.89334 1.13747L0.312715 8.85967C-0.104238 9.56273 -0.104238 10.4316 0.312715 11.1403L4.89334 18.8625C5.31029 19.5656 6.08547 20 6.91938 20H16.0806C16.9145 20 17.6897 19.5656 18.1067 18.8625L22.6873 11.1403C23.1042 10.4373 23.1042 9.56845 22.6873 8.85967L18.1067 1.13747C17.6897 0.43441 16.9145 0 16.0806 0Z' fill='#{color(Gray, Gray-300)}'/%3E%3C/svg%3E%0A");
                            color: color(base, Primary);
                        }
                    }
                }

                .--faq-content {
                    padding: 0 .5rem 2rem;
                    font-size: 1.6rem;
                    line-height: 2.4rem;
                    border-bottom: .1rem solid color(Gray, Gray-300);

                    p {
                        font-size: 1.6rem;
                        line-height: 2.4rem;

                        &:not(:last-of-type) {
                            margin-bottom: 2rem;
                        }
                    }
                }

                &[open] summary {
                    border: none;
                    background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cmask id='mask0_2_8' style='mask-type:alpha' maskUnits='userSpaceOnUse' x='0' y='0' width='24' height='24'%3E%3Crect x='24' y='24' width='24' height='24' transform='rotate(-180 24 24)' fill='%23D9D9D9'/%3E%3C/mask%3E%3Cg mask='url(%23mask0_2_8)'%3E%3Cpath d='M12 8.625L18 14.625L16.6 16.025L12 11.425L7.4 16.025L6 14.625L12 8.625Z' fill='black'/%3E%3C/g%3E%3C/svg%3E%0A");
                    color: color(base, Black);
                }

                &[open] summary ~ * {
                    animation: open .5s ease-in-out;
                }

                @keyframes open {
                    0% {
                        opacity: 0;
                    }
                    100% {
                        opacity: 1;
                    }
                }
            }
        }

        .--actions {
            display: flex;
            justify-content: flex-end;
            margin: 2rem 0;
        }

        &.--one-column {
            .--content {
                padding-left: 0;
                padding-right: 0;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
        }
    }
}
