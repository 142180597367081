.whats__inside__container {
    margin: 0;
    padding: 2rem 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include breakpoint(medium) {
        margin: 0;
        padding: 5rem 5rem 0;
    }

    .whats__inside {
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat(2, 1fr);

        @include breakpoint(medium) {
            grid-template-columns: repeat(2, 1fr);
        }

        > div {
            position: relative;
            cursor: pointer;
            overflow: hidden;
            display: none;

            .--overlay {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                flex-direction: column-reverse;

                > span {
                    text-align: center;
                    background-color: color(base, DefaultFontColor);
                    color: color(base, White);
                    font-size: 1.5rem;
                    padding: .5rem 1rem .5rem;
                    line-height: 2rem;
                    text-transform: uppercase;
                    font-weight: 600;
                    position: relative;
                }

                > div {
                    transition: all 0.3s ease;
                    color: color(base, White);
                    font-size: 1.3rem;
                    line-height: 1.2;
                    text-align: center;
                    border-bottom: .3rem solid color(Gray, Gray-600);
                    background: linear-gradient(180deg, rgba(29, 29, 27, 0.8) 0%, rgba(38, 38, 38, 0.8) 25%, rgba(38, 38, 38, 0.8) 75%, rgba(29, 29, 27, 0.8) 100%);
                    flex: 0;
                    overflow: hidden;

                    .--content {
                        height: 100%;
                        padding: 1rem;

                        > div {
                            height: 100%;
                            margin: 0.5rem 0;

                            a {
                                display: flex;
                                height: 100%;
                                align-items: center;
                                justify-content: center;
                                font-size: 1.6rem;
                                color: color(base, PrimaryButton);
                                position: relative;

                                @include hover() {
                                    text-decoration: underline;
                                }

                                &::after {
                                    z-index: 0;
                                }
                            }
                        }
                    }
                }
            }

            img {
                aspect-ratio: 1 / 1;
                margin-bottom: 27.5px;
            }

            &:hover {
                .--overlay {
                    > div {
                        flex: 1;
                    }
                }
            }
        }

        &.global__content + button {
            display: block;
            line-height: 4rem;
            margin: 2rem 0 0;
            padding: .9rem 4.5rem .9rem 3rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
            background-repeat: no-repeat;
            background-position: right 1.5rem center;
            background-size: 1.8rem;

            @include hover() {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, PrimaryButtonHover)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M19.5 8.25l-7.5 7.5-7.5-7.5' /%3E%3C/svg%3E%0A");
                p {
                    color: color(base, PrimaryButtonHover);
                }
            }

            &:focus {
                p {
                    color: color(base, White)
                }
            }

            p {
                color: color(base, PrimaryButton);
                font-size: 1.4rem;
            }
        }
    }

    .button-double {
        margin: 3rem 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        //@include button-double();

        p {
            cursor: pointer;
            font-size: 1.6rem !important;

            &:first-of-type::before {
                right: calc(100% - .4rem) !important;
            }
        }

        &.--down {
            .arrow-down {
                height: 1rem;
                transform: rotate(180deg);
            }
        }

        .arrow-down {
            width: 2rem;
            height: 1.3rem;
            display: inline-block;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{color(base, White)}" d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z"/></svg>');
            background-position: center;
            background-repeat: no-repeat;
            background-size: 1.2rem;
        }
    }
}

@keyframes pulse {
    50% {
        background-color: inherit;
    }
}

.modal__content .global__content {
    padding: 2rem;
}
