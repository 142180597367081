.blaze-pagination {
    display: flex;
    gap: .5rem;
    justify-content: center;
    margin: 2rem 0 0;

    button {
        @include transition;
        background: transparent;
        border: .1rem solid color(base, PrimaryButton);
        border-radius: 50%;
        height: .8rem;
        text-indent: -999rem;
        width: .8rem;

        &.active {
            background: color(base, PrimaryButton);
        }
    }
}

.swiper__pagination {
    display: flex;
    justify-content: center;

    .swiper-pagination-bullet {
        background: color(Gray, Gray-300);

        &.swiper-pagination-bullet-active {
            background: color(base, PrimaryButton);
        }
    }
}
