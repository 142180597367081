.homepage__seo-with-product--block-wrapper {

    .--content {
        h1 {
            margin: 0 0 1rem;
            font-size: 2.4rem;
        }

        img {
            float: left;
            margin: 1rem 1rem 1rem 0;
        }

        p {
            &:not(:last-child) {
                margin: 0 0 .5rem;
            }

            line-height: 2.4rem;
            font-size: 1.4rem;
        }
    }

    .catalog__category-list {
        @include breakpoint(medium) {
            border-left: .1rem solid color(gray, Gray-200);
        }

        > ul {
            border-bottom: none;
            margin-bottom: 0;
        }

        .content__block {
            display: flex;
            flex-flow: column;
            align-items: center;
            gap: 1rem;
            padding: 1rem;

            > span {
                @include transition;
                display: block;
                color: color(base, DefaultFontColor);
                font-size: 1.4rem;

                &.--title {
                    font-weight: 700;
                    text-transform: uppercase;
                    line-height: 2.8rem;
                }

                &.--sub-title {
                    line-height: 2.1rem;
                    width: 70%;
                    border-bottom: .3rem solid transparent;
                    padding: 0 0 1rem;
                    text-align: center;
                }
            }

            &:is(a) {
                @include hover() {
                    .--title {
                        color: color(base, PrimaryButton);
                    }
                    .--sub-title {
                        border-color: color(base, DefaultFontColor);
                    }
                }
            }
        }

        .product__tile {
            border: none;
            padding: 0;
            margin: 0;

            > a .product__holder {
                .product__label, ul {
                    display: none;
                }
            }
        }
    }

    @include breakpoint(small only) {
        .grid-x.grid-padding-x {
            > div:first-child > div {
                padding: 0 0 2rem;
                margin: 0 0 2rem;
                border-bottom: .5rem solid color(base, DefaultFontColor);
            }
        }
    }
}
