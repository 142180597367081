.catalog-product-page_body {
    .product__configuration {
        display: flex;
        flex-direction: column;

        .product__price-container {
            order: 2;
            border: none;
        }

        .product__option {
            order: 1;
        }

        .option__attributes--container {
            display: block;

            .product__option {
                display: block;

                .product__configurable {
                    .product__configurable-option {
                        dl {
                            dd.swatch__holder {
                                position: relative;
                                gap: 0;
                                grid-template-columns: none;
                                display: block;
                                padding: 0 2rem;

                                .swiper__button {
                                    position: absolute;
                                    top: 50%;
                                    transform: translateY(-50%);
                                    z-index: 2;

                                    &.--next {
                                        right: 0;
                                    }

                                    &.--prev {
                                        left: 0;
                                    }
                                }

                                .swiper_options-product {
                                    width: 100%;

                                    .swiper-slide {
                                        transition: opacity .5s ease-in-out, transform .5s ease-in-out;
                                        opacity: 0.5;
                                        transform: scale(.8);

                                        label {
                                            display: block;
                                            border-bottom: .3rem solid transparent;
                                            padding: 0;

                                            span {
                                                width: 100%;
                                                position: relative;
                                                display: block;
                                                cursor: pointer;
                                                border: 0;

                                                img {
                                                    display: block;
                                                    width: 100%;
                                                    aspect-ratio: 1/1;
                                                    border: none;
                                                }
                                            }
                                        }

                                        &.swiper-slide-active {
                                            opacity: 1;
                                            transform: scale(1);

                                            label {
                                                border-color: color(Gray, Gray-800);
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .product__configurable-option__select {
                            select {
                                background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28.09 28.09' style='enable-background:new 0 0 28.09 28.09' xml:space='preserve'%3E%3Cpath d='M20.4 0H7.7C3.45 0 0 3.45 0 7.7v12.7c0 4.25 3.45 7.7 7.7 7.7h12.7c4.25 0 7.7-3.45 7.7-7.7V7.7C28.09 3.45 24.65 0 20.4 0zm1.46 19.18c0 .31-.19.61-.55.86l-5.63 3.86c-.7.48-2.02.48-2.72 0l-5.63-3.86c-.35-.24-.55-.55-.55-.86v-1.91c0-.21.48-.3.72-.14l6.24 4.28c.3.2.86.2 1.16 0l6.24-4.28c.24-.16.72-.07.72.14v1.91zm0-8.19c0 .21-.48.3-.72.14L14.9 6.85c-.3-.2-.86-.2-1.16 0L7.5 11.13c-.24.16-.72.07-.72-.14V9.08c0-.31.19-.61.55-.86l5.63-3.86c.7-.48 2.02-.48 2.72 0l5.63 3.86c.35.24.55.55.55.86v1.91z' style='fill:%23c6c6c5'/%3E%3C/svg%3E");
                                background-position: right 7px center;
                                border: 2px solid #c6c6c6;
                                border-radius: 5px;
                                color: #c6c6c6;
                                font-weight: 700;
                                min-width: 0;
                                padding-right: 36px;
                            }
                        }
                    }
                }
            }
        }

    }
}
