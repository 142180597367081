.checkout-checkout_body {
    #sticky__account-menu-trigger, footer {
        display: none;
    }

    header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 0 auto 2rem;
        padding: 1rem;
        max-width: 78rem;
        border-bottom: .2rem solid color(base, DefaultFontColor);

        @include breakpoint(medium) {
            padding: 2rem 0;
            border-width: .5rem;
        }

        .--logo {
            margin-top: 0.5rem;

            img {
                max-width: 15rem;

                @include breakpoint(medium) {
                    max-width: 18rem;
                }
            }
        }

        @include breakpoint(small only) {
            h1 {
                display: none;
            }
        }
    }

    .checkout-checkout {
        max-width: 80rem;
        margin: 0 auto;

        .checkout-messages__container {
            > span {
                margin: 0 0 2rem;
            }
        }

        .progress-bar {
            display: flex;
            align-items: center;
            justify-content: space-evenly;
            margin: 0 0 2rem;

            > span {
                flex: 1;
                position: relative;

                > span {
                    &::before, &::after {
                        content: '';
                        border-style: solid;
                        height: 0;
                        position: absolute;
                        width: 0;
                        border-width: 1.6rem 0 1.6rem 1.5rem;
                        top: 0;

                        @include breakpoint(medium) {
                            border-width: 2rem 0 2rem 1.5rem;
                        }
                    }

                    &::after {
                        border-color: transparent color(Gray, Gray-250);
                        right: 0;

                        @include breakpoint(medium) {
                            right: 1rem;
                        }
                    }

                    &::before {
                        border-color: color(Gray, Gray-250) transparent;
                        left: -.5rem;

                        @include breakpoint(medium) {
                            left: -1.5rem;
                        }
                    }

                    > span {
                        display: block;
                        background: color(Gray, Gray-250);
                        font-weight: 600;
                        text-align: center;
                        font-size: 1.3rem;
                        line-height: 3.2rem;
                        margin: 0 1.5rem 0 1rem;

                        @include breakpoint(medium) {
                            font-size: 1.6rem;
                            line-height: 4rem;
                            margin: 0 2.5rem 0 0;
                        }
                    }
                }

                &.--active, &.--complete {
                    cursor: pointer;

                    > span {
                        > span {
                            color: color(base, White);
                            background-color: color(base, PrimaryButton);
                        }

                        &::before {
                            border-color: color(base, PrimaryButton) transparent;
                        }

                        &::after {
                            border-color: transparent color(base, PrimaryButton);
                        }
                    }
                }

                &.--complete > span {
                    > span {
                        background-color: color(base, Green);
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='2' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-size: 2rem;
                        background-position: right center;

                        @include breakpoint(small only) {
                            background-image: none;
                        }
                    }

                    &::before {
                        border-color: color(base, Green) transparent;
                    }

                    &::after {
                        border-color: transparent color(base, Green);
                    }
                }

                &:first-of-type {
                    > span {
                        &::before {
                            display: none;
                        }

                        > span {
                            border-radius: .5rem 0 0 .5rem;

                            @include breakpoint(small only) {
                                margin: 0 1.5rem 0 0;
                            }
                        }
                    }
                }

                &:last-of-type {
                    > span {
                        &::after {
                            display: none;
                        }

                        > span {
                            border-radius: 0 .5rem .5rem 0;
                            margin: 0 0 0 1rem;

                            @include breakpoint(medium) {
                                margin: 0;
                            }
                        }
                    }
                }
            }
        }

        .checkout-step-container {
            h2 {
                font-size: 2rem;
                line-height: 3rem;
                margin: 0 0 1rem;
            }

            .--loading-methods {
                display: flex;
                align-items: center;
                gap: 1rem;

                .--loading {
                    @include loading();
                }
            }

            .form-field-container {
                background: color(Gray, Gray-100);
                padding: 1.5rem;
                margin: 1rem 0 0;
                display: flex;
                flex-direction: column;
                gap: 1rem;

                @include breakpoint(medium) {
                    max-width: 80%;
                }

                .field__group {
                    display: flex;
                    gap: 1rem;

                    .checkout__field {
                        flex: 1;

                        label {
                            display: block;
                            margin: 0 0 .3rem;
                        }

                        input, select {
                            width: 100%;
                        }
                    }
                }
            }

            .address__selection {
                .--item {
                    border-bottom: .3rem solid color(base, DefaultFontColor);
                    margin: 0 0 1rem;
                    padding: 0 0 1.5rem;

                    label {
                        display: block;
                        cursor: pointer;

                        > strong {
                            display: block;
                            margin: 0 0 .5rem;
                        }

                        address {
                            line-height: 2.4rem;
                        }

                        button {
                            margin: 1rem 0 0;
                        }
                    }

                    input[type="radio"] {
                        opacity: 0;
                        position: absolute;
                    }

                    input[type="radio"]:checked + label {
                        cursor: initial;
                        padding: 1rem;
                        background-color: color(Gray, Gray-100);
                        background-repeat: no-repeat;
                        background-position: right 1rem center;
                        background-size: 5rem;
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(Gray, Gray-300)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M4.5 12.75l6 6 9-13.5' /%3E%3C/svg%3E%0A");
                    }
                }
            }

            .shipping__methods {
                padding: 2rem;
                border: .1rem solid color(Gray, Gray-100);

                .shipping__method {
                    margin: .5rem 0;

                    input[type="radio"] {
                        opacity: 0;
                        position: absolute;
                    }

                    label {
                        cursor: pointer;
                        display: block;
                        position: relative;
                        padding-left: 3rem;

                        &::before {
                            content: '';
                            width: 2rem;
                            height: 2rem;
                            border: .1rem solid color(Gray, Gray-200);
                            border-radius: 50%;
                            margin: .8rem 1rem 0 0;
                            position: absolute;
                            left: 0;
                        }

                        > span {
                            display: block;

                            > span {
                                display: block;

                                &.--title {
                                    font-weight: 600;
                                    font-size: 1.6rem;
                                    line-height: 3.6rem;
                                    display: flex;
                                    align-items: center;
                                    gap: .5rem;

                                    > span {
                                        font-size: 1.6rem;
                                        line-height: 3.6rem;

                                        &.--free {
                                            color: color(base, Green);
                                            font-weight: 700;
                                        }
                                    }
                                }

                                &.--sub {
                                    font-size: 1.4rem;
                                    line-height: 2.4rem;
                                }
                            }
                        }
                    }

                    input[type="radio"]:checked + label::before {
                        border: .6rem solid color(base, PrimaryButton);
                    }
                }
            }

            .payment__methods {
                padding: 2rem;
                border: .1rem solid color(Gray, Gray-100);

                .payment__method {
                    margin: 1rem 0 0;

                    label {
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                        gap: 1.5rem;

                        > span {
                            display: block;

                            .--title {
                                display: block;
                                font-weight: 700;
                                font-size: 1.5rem;
                                line-height: 2rem;
                            }

                            .--sub {
                                display: block;
                                font-size: 1.3rem;
                                line-height: 2rem;
                            }
                        }
                    }

                    .payment__method__fields {
                        @include border-radius();
                        background-color: color(Gray, Gray-100);
                        padding: 1rem;
                        margin: 1rem 0 0;

                        .input__holder {
                            &:not(:last-child) {
                                margin: 0 0 .5rem;
                            }

                            label {
                                margin: 0 0 .4rem;
                            }

                            input[type="text"] {
                                height: 3.4rem;
                                font-size: 1.4rem;
                                width: 100%;

                                @include breakpoint(medium) {
                                    width: 60%;
                                }
                            }
                        }
                    }
                }
            }
        }

        .--right {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            height: 100%;

            .shipping__information {
                flex: 1;
                background: color(Gray, Gray-100);
                padding: 2rem;

                h4 {
                    margin: 0 0 1rem;
                }

                > button {
                    margin: 1rem 0 0;
                    color: color(base, PrimaryButton);

                    @include hover() {
                        text-decoration: underline;
                    }
                }
            }

            .coupon-code__container {
                .toggle-coupon {
                    display: flex;
                    align-items: center;
                    gap: 1rem;

                    label {
                        cursor: pointer;
                    }
                }

                .--loading-coupon {
                    display: flex;
                    align-items: center;
                    gap: 1rem;
                    margin: 1rem 0 0;
                    font-size: 1.2rem;

                    .--loading {
                        @include loading();
                    }
                }

                .coupon-code__form {
                    margin: 1rem 0 0;

                    .form-holder {
                        display: flex;
                        gap: 1rem;

                        input {
                            flex: 1;
                            line-height: 4rem;
                            height: 4rem;
                        }

                        button {
                            line-height: 4rem;
                        }
                    }
                }
            }
        }

        .checkout__summary {
            margin: 2rem 0 0;

            .--header {
                margin: 0 0 1rem;
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .summary__block {
                border-top: .2rem solid color(base, DefaultFontColor);

                .--loading {
                    @include loading();
                    display: flex;
                    justify-content: center;
                    margin: 2rem;
                }

                .cart__items {
                    > div {
                        border-bottom: .1rem solid color(Gray, Gray-100);
                        display: flex;
                        align-items: center;
                        padding: .5rem 0;
                        gap: 3rem;

                        .--details {
                            flex: 0 0 45%;

                            span {
                                display: block;

                                &.--title {
                                    font-weight: 600;
                                }
                            }
                        }

                        .--attributes {
                            span {
                                display: block;
                                font-size: 1.1rem;
                            }
                        }

                        > span {
                            margin-left: auto;
                        }
                    }

                    .--actions {
                        justify-content: center;
                        padding: 2rem 0;
                    }
                }

                .cart__totals {
                    border-top: .2rem solid color(base, DefaultFontColor);
                    display: flex;
                    justify-content: flex-end;
                    padding: 1.5rem 0 0;

                    .--container {
                        @include breakpoint(medium) {
                            flex: 0 0 50%;
                        }

                        > div {
                            display: flex;
                            justify-content: space-between;
                            gap: 10rem;

                            &.--grand {
                                font-weight: 700;
                                margin: .5rem 0 0;
                            }

                            span {
                                font-size: 1.5rem;
                                line-height: 2.4rem;

                                &.--free {
                                    color: color(base, Green);
                                    font-weight: 700;
                                }
                            }
                        }
                    }
                }
            }
        }

        .checkout-footer {
            margin: 2rem 0;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 3rem;

            @include breakpoint(medium) {
                justify-content: flex-end;
            }

            > a {
                font-size: 1.3rem;

                @include hover() {
                    text-decoration: underline;
                }
            }
        }
    }
}
