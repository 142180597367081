.pagination {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    @include breakpoint(small only) {
        padding-top: 3rem;
    }
    ul {
        display: flex;
        gap: .4rem;

        li {
            a, span {
                @include transition();
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                line-height: 2.8rem;
                width: 2.8rem;
                height: 2.8rem;
                font-size: 1.4rem;
                font-weight: 900;
                background: color(Dane, White);
                color: color(Dane, Mesh);
                border: .1rem solid color(Dane, Mesh);
                border-radius: 0;

                @include hover {
                    &.--bg {
                        background-color: transparent;
                    }

                    &::before {
                        display: none;
                    }

                    &:not(.--current):not(.--first):not(.--prev):not(.--next):not(.--last) {
                        background-color: color(Dane, MeshMembrane);
                        border: .1rem solid color(Dane, MeshMembrane);
                        color: color(Dane, White);
                    }
                }

                &.--current {
                    background: color(Dane, Mesh);
                    color: color(Dane, White);

                    &::before {
                        display: none;
                    }
                    .pagination-item__footer {
                        display: none;
                    }
                }

                &.--first {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{color(Dane, Mesh)}" d="M177.5 414c-8.8 3.8-19 2-26-4.6l-144-136C2.7 268.9 0 262.6 0 256s2.7-12.9 7.5-17.4l144-136c7-6.6 17.2-8.4 26-4.6s14.5 12.5 14.5 22l0 72 288 0c17.7 0 32 14.3 32 32l0 64c0 17.7-14.3 32-32 32l-288 0 0 72c0 9.6-5.7 18.2-14.5 22z"/></svg>');
                    background-color: transparent;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 2.2rem;
                    margin-right: .5rem;
                    border: 0;
                }

                &.--prev {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#{color(Dane, Mesh)}" d="M9.4 278.6c-12.5-12.5-12.5-32.8 0-45.3l128-128c9.2-9.2 22.9-11.9 34.9-6.9s19.8 16.6 19.8 29.6l0 256c0 12.9-7.8 24.6-19.8 29.6s-25.7 2.2-34.9-6.9l-128-128z"/></svg>');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-size: 1rem;
                    width: 1rem;
                    margin-right: .5rem;
                    border: 0;
                }

                &.--next {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 512"><path fill="#{color(Dane, Mesh)}" d="M246.6 278.6c12.5-12.5 12.5-32.8 0-45.3l-128-128c-9.2-9.2-22.9-11.9-34.9-6.9s-19.8 16.6-19.8 29.6l0 256c0 12.9 7.8 24.6 19.8 29.6s25.7 2.2 34.9-6.9l128-128z"/></svg>');
                    background-position: center;
                    background-repeat: no-repeat;
                    background-color: transparent;
                    background-size: 1rem;
                    width: 1rem;
                    margin-left: .5rem;
                    border: 0;
                }

                &.--last {
                    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="#{color(Dane, Mesh)}" d="M334.5 414c8.8 3.8 19 2 26-4.6l144-136c4.8-4.5 7.5-10.8 7.5-17.4s-2.7-12.9-7.5-17.4l-144-136c-7-6.6-17.2-8.4-26-4.6s-14.5 12.5-14.5 22l0 72L32 192c-17.7 0-32 14.3-32 32l0 64c0 17.7 14.3 32 32 32l288 0 0 72c0 9.6 5.7 18.2 14.5 22z"/></svg>');
                    background-color: transparent;
                    background-position: center;
                    background-repeat: no-repeat;
                    background-size: 2.2rem;
                    margin-left: .5rem;
                    border: 0;
                }
            }
        }
    }
    .toolbar__bottom & {
        ul {
            align-items: flex-end;
        }
        a {
            &:not(.--current):not(.--first):not(.--prev):not(.--next):not(.--last) {
                background-color: color(Dane, Mesh);
                border: .1rem solid color(Dane, Mesh);
                color: color(Dane, White);
            }

            &.--first {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{color(Dane, Mesh)}" d="M267.5 440.6c9.5 7.9 22.8 9.7 34.1 4.4s18.4-16.6 18.4-29V96c0-12.4-7.2-23.7-18.4-29s-24.5-3.6-34.1 4.4l-192 160L64 241V96c0-17.7-14.3-32-32-32S0 78.3 0 96v320c0 17.7 14.3 32 32 32s32-14.3 32-32V271l11.5 9.6 192 160z"/></svg>');
                background-size: 1.1rem;
                background-position: bottom -1px right;
            }
            &.--prev {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="66" height="61" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" clip-rule="evenodd" version="1.1"><g fill-rule="nonzero"><path fill="#{color(Dane, Mesh)}" d="M.975 46.2c-1.3-1.2-1.3-3.3 0-4.5l14.4-13.4c2-1.8 5.2-.4 5.2 2.3v8.2h35V28c0-.4.2-.8.5-1.1l7.2-7.2c1-1 2.6-.3 2.6 1.1V46c0 1.7-1.4 3.1-3.1 3.1h-42.2v8.2c0 2.7-3.2 4.1-5.2 2.3L.975 46.2ZM27.175 14.2V1h3.3v10.4h7v2.9h-10.2l-.1-.1ZM43.775 14.4c-1 0-1.9-.2-2.8-.7-.8-.5-1.5-1.1-2-1.9-.5-.8-.7-1.7-.7-2.6 0-.9.2-1.8.7-2.6.5-.8 1.1-1.4 1.9-1.9.8-.5 1.7-.7 2.6-.7.9 0 1.9.2 2.6.7.8.5 1.4 1.1 1.8 1.9.4.8.7 1.8.7 2.8v.8h-7.1c.1.3.3.6.6.8.2.2.5.4.8.6.3.2.7.2 1.1.2.4 0 .8 0 1.1-.2.3-.2.6-.3.9-.6l2 1.8c-.6.5-1.3.9-1.9 1.2-.7.2-1.4.4-2.2.4h-.1Zm-2.3-6.3h4.2c-.1-.3-.3-.6-.5-.8-.2-.2-.5-.4-.7-.5-.2-.1-.6-.2-.9-.2-.3 0-.6 0-.9.2-.3.1-.5.3-.7.5-.2.2-.3.5-.4.9l-.1-.1ZM51.375 14.2V6.8h-2.2V4.2h2.2v-.6c0-1.2.3-2.1 1-2.7.7-.6 1.7-.9 3-.9h.9c.3 0 .6 0 .8.1v2.5c-.2 0-.5 0-.6-.1h-.6c-.5 0-.8 0-1.1.3-.3.3-.4.5-.4.8v.5h2.6v2.6h-2.6v7.4h-3.1l.1.1ZM63.375 14.4c-1.2 0-2.1-.3-2.7-.8-.6-.5-.9-1.3-.9-2.4V6.8h-2V4.2h2V1.6l3.1-.7v3.2h2.8v2.6h-2.8v3.8c0 .4 0 .8.3.9.2.2.5.3 1 .3h.7c.2 0 .4 0 .7-.2V14c-.3 0-.6.2-1.1.2h-1.1v.2Z"/></g></svg>');
                width: 3rem;
                background-size: 3rem;
            }
            &.--next {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xml:space="preserve" width="66" height="61" fill-rule="evenodd" stroke-linejoin="round" stroke-miterlimit="2" clip-rule="evenodd" version="1.1"><g fill-rule="nonzero"><path fill="#{color(Dane, Mesh)}" d="M50.5 59.7c-2 1.8-5.2.4-5.2-2.3v-8.2H3.1c-1.7 0-3.1-1.4-3.1-3.1V20.9c0-1.4 1.7-2.1 2.6-1.1L9.8 27c.3.2.5.7.5 1.1v10.8h35v-8.2c0-2.7 3.2-4.1 5.2-2.3l14.4 13.4c1.3 1.2 1.3 3.3 0 4.5L50.5 59.7ZM1.1 14.3V1.1h6.8c.9 0 1.7.2 2.4.5.7.4 1.3.9 1.7 1.5.4.6.6 1.4.6 2.2 0 .8-.2 1.6-.7 2.2-.5.7-1.1 1.2-1.8 1.5l2.9 5.3H9.4L6.9 9.5H4.4v4.8H1.1Zm3.3-7.4h3.2c.5 0 .9-.1 1.2-.4.3-.3.5-.6.5-1.1 0-.5-.2-.8-.5-1.1-.3-.3-.7-.4-1.2-.4H4.4v3ZM15.6 3.4c-.5 0-.9-.2-1.2-.5-.3-.3-.5-.7-.5-1.2s.2-.9.5-1.2c.3-.3.7-.5 1.2-.5s.9.2 1.2.5c.3.3.5.7.5 1.2s-.2.9-.5 1.2c-.3.3-.7.5-1.2.5Zm-1.5 10.9v-10h3.1v10h-3.1ZM24.1 18.4c-.8 0-1.6 0-2.3-.2-.7-.2-1.4-.4-2-.6l1-2.4c.5.3 1.1.4 1.6.6.5.1 1.1.2 1.6.2s1.3-.1 1.6-.4c.3-.3.5-.7.5-1.3v-.6c-.8.5-1.6.8-2.6.8s-1.8-.2-2.6-.7c-.8-.5-1.4-1.1-1.8-1.8-.5-.8-.7-1.6-.7-2.6s.2-1.8.7-2.6C19.6 6 20.2 5.4 21 5c.8-.4 1.7-.7 2.6-.7.9 0 .9 0 1.3.2.4.1.8.3 1.2.5v-.5h3v9.8c0 1.3-.4 2.4-1.3 3.1-.9.7-2.2 1.1-3.8 1.1l.1-.1Zm.1-6.6c.4 0 .8 0 1.1-.2.3-.2.7-.3.9-.5V7.6c-.3-.2-.6-.4-.9-.5-.3-.1-.7-.2-1.1-.2-.4 0-.9.1-1.3.3-.4.2-.7.5-.9.9-.2.4-.4.8-.4 1.3s.1.9.3 1.3c.2.4.5.7.9.9.4.2.8.3 1.3.3l.1-.1ZM31.2 14.4V1.1l3.1-.7V5c.8-.6 1.7-.9 2.7-.9 1 0 1.5.2 2.1.5.6.3 1.1.8 1.4 1.4.3.6.5 1.3.5 2.1v6.2h-3.1V8.6c0-.6-.2-1-.5-1.3-.3-.3-.8-.5-1.3-.5s-.7 0-1 .2c-.3.1-.6.3-.8.6v6.8h-3.1ZM47.5 14.5c-1.2 0-2.1-.3-2.7-.8-.6-.5-.9-1.3-.9-2.4V6.9h-2V4.3h2V1.7L47 1v3.2h2.8v2.6H47v3.8c0 .4 0 .8.3.9.3.1.5.3 1 .3h.7c.2 0 .4 0 .7-.2v2.5c-.3 0-.6.2-1.1.2h-1.1v.2Z"/></g></svg>');
                width: 3rem;
                background-size: 3rem;
            }
            &.--last {
                background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path fill="#{color(Dane, Mesh)}" d="M52.5 440.6c-9.5 7.9-22.8 9.7-34.1 4.4S0 428.4 0 416V96c0-12.4 7.2-23.7 18.4-29s24.5-3.6 34.1 4.4l192 160L256 241V96c0-17.7 14.3-32 32-32s32 14.3 32 32v320c0 17.7-14.3 32-32 32s-32-14.3-32-32V271l-11.5 9.6-192 160z"/></svg>');
                background-size: 1.1rem;
                background-position: bottom -1px left;
            }
        }
        .--current {
            position: relative;
            width: 4.8rem;
            height: 4.8rem;
            justify-content: flex-start;
            background-color: color(Dane, White);
            color: color(Dane, Mesh);
            font-size: 1.6rem;

            > .pagination-item__footer {
                display: block;
                position: absolute;
                width: 100%;
                font-size: 1.1rem;
                color: color(Dane, Mesh);
                border: 0;
                bottom: 0;
                pointer-events: none;
                text-align: center;
                &:after {
                    content: "";
                    display: block;
                    width: 100%;
                    height: .3rem;
                    background: linear-gradient(to right, color(Dane, Mesh) 0%, color(Dane, Mesh) 50%, color(Dane, White) 50%, color(Dane, White) 100%);
                    position: absolute;
                    bottom: 0;
                    left: 0;

                }
            }
        }
    }
}

.swiper__button {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="352" height="320" fill="#{color(Dane, Mesh)}" viewBox="130.734 45.847 352 320"><path d="m348.634 55.747 122.8 122.8c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3l-122.8 122.8c-6.4 6.4-15 9.9-24 9.9-18.7 0-33.9-15.2-33.9-33.9v-62.1h-128c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32h128v-62.1c0-18.7 15.2-33.9 33.9-33.9 9 0 17.6 3.6 24 9.9Z"/></svg>');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    height: 1.4rem;
    width: 1.6rem;

    &.--prev {
        background-image: url('data:image/svg+xml,<svg viewBox="130.734 45.847 352 320" width="352" height="320" fill="#{color(Dane, Mesh)}" xmlns="http://www.w3.org/2000/svg"><path d="M 348.634 55.747 L 471.434 178.547 C 478.634 185.747 482.734 195.647 482.734 205.847 C 482.734 216.047 478.634 225.947 471.434 233.147 L 348.634 355.947 C 342.234 362.347 333.634 365.847 324.634 365.847 C 305.934 365.847 290.734 350.647 290.734 331.947 L 290.734 269.847 L 162.734 269.847 C 145.034 269.847 130.734 255.547 130.734 237.847 L 130.734 173.847 C 130.734 156.147 145.034 141.847 162.734 141.847 L 290.734 141.847 L 290.734 79.747 C 290.734 61.047 305.934 45.847 324.634 45.847 C 333.634 45.847 342.234 49.447 348.634 55.747 Z" style="transform-origin: 306.734px 205.847px;" transform="matrix(-1, 0, 0, -1, 0, 0)"/></svg>');
    }

    &.swiper-button-disabled {
        visibility: hidden;
    }

    @include hover() {
        //background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, PrimaryButton)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M8.25 4.5l7.5 7.5-7.5 7.5' /%3E%3C/svg%3E%0A");
        background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="352" height="320" fill="#{color(Dane, Red)}" viewBox="130.734 45.847 352 320"><path d="m348.634 55.747 122.8 122.8c7.2 7.2 11.3 17.1 11.3 27.3s-4.1 20.1-11.3 27.3l-122.8 122.8c-6.4 6.4-15 9.9-24 9.9-18.7 0-33.9-15.2-33.9-33.9v-62.1h-128c-17.7 0-32-14.3-32-32v-64c0-17.7 14.3-32 32-32h128v-62.1c0-18.7 15.2-33.9 33.9-33.9 9 0 17.6 3.6 24 9.9Z"/></svg>');
        &.--prev {
            background-image: url('data:image/svg+xml,<svg viewBox="130.734 45.847 352 320" width="352" height="320" fill="#{color(Dane, Red)}" xmlns="http://www.w3.org/2000/svg"><path d="M 348.634 55.747 L 471.434 178.547 C 478.634 185.747 482.734 195.647 482.734 205.847 C 482.734 216.047 478.634 225.947 471.434 233.147 L 348.634 355.947 C 342.234 362.347 333.634 365.847 324.634 365.847 C 305.934 365.847 290.734 350.647 290.734 331.947 L 290.734 269.847 L 162.734 269.847 C 145.034 269.847 130.734 255.547 130.734 237.847 L 130.734 173.847 C 130.734 156.147 145.034 141.847 162.734 141.847 L 290.734 141.847 L 290.734 79.747 C 290.734 61.047 305.934 45.847 324.634 45.847 C 333.634 45.847 342.234 49.447 348.634 55.747 Z" style="transform-origin: 306.734px 205.847px;" transform="matrix(-1, 0, 0, -1, 0, 0)"/></svg>');
        }
    }
}
