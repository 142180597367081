.search__autocomplete {
    position: relative;

    .--form {
        position: relative;

        input {
            width: 100%;
            font-size: 1.3rem;
            background-color: transparent;
            border: 0;
            border-bottom: .2rem solid color(Dane, Mesh);
            border-radius: 0;
            color: color(Dane, Mesh);
            padding-left: 0;
            height: 3.2rem;
            line-height: 3.2rem;
        }

        .--loading {
            @include loading();
            position: absolute;
            right: .8rem;
            top: 50%;
            transform: translateY(-50%);
        }

        button, span.--remove {
            @include transition();
            position: absolute;
            width: 2rem;
            height: 2rem;
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(Dane, Mesh)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z' /%3E%3C/svg%3E%0A");
            background-size: 2rem;
            background-repeat: no-repeat;
            background-position: center;
            top: 50%;
            transform: translateY(-50%);
            right: .5rem;

            @include hover() {
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(Gray, Gray-600)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z' /%3E%3C/svg%3E%0A");
            }

            &.--remove {
                right: 3.5rem;
                background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Dane, Mesh)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                cursor: pointer;

                @include hover() {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='1.5' stroke='#{color(Gray, Gray-600)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M6 18L18 6M6 6l12 12' /%3E%3C/svg%3E%0A");
                }
            }
        }
    }

    .search__autocomplete_dropdown {
        @include border-radius();
        position: absolute;
        background: color(base, White);
        top: calc(100% + 1rem);
        z-index: 3;
        border: .1rem solid color(Gray, Gray-200);
        display: flex;
        left: 0;
        right: 0;

        @include breakpoint(medium) {
            left: 0;
            right: -25rem;
        }

        > div {
            h4 {
                padding: .5rem 2rem;
            }
        }

        .--extra {
            border-left: .1rem solid color(Gray, Gray-200);
            flex: 0 0 30%;

            .cms__result, .category__result {
                padding-bottom: 1rem;

                a {
                    @include transition();
                    display: flex;
                    color: color(base, DefaultFontColor);
                    font-size: 1.4rem;
                    padding: .5rem 2rem;

                    @include hover() {
                        color: color(base, PrimaryButton);
                    }
                }
            }
        }

        .--products {
            flex: 1;

            .product__result {
                a, label {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    gap: 2rem;

                    &.--active {
                        background: color(Gray, Gray-100);
                    }

                    &:not(:first-child) {
                        border-top: .1rem solid color(Gray, Gray-200);
                    }

                    .image__holder {
                        display: flex;

                        img {
                            @include transition();
                        }
                    }

                    .--info {
                        display: block;
                        padding: 1rem 0;

                        > span {
                            display: block;
                            color: color(base, DefaultFontColor);

                            &.--title {
                                @include transition();
                                font-weight: 700;
                            }

                            &.--model {
                                font-size: 1.1rem;
                            }

                            &.price__holder {
                                display: flex;
                                margin: 1rem 0 0;

                                > span {
                                    font-size: 1.6rem;
                                    font-weight: 700;
                                }
                            }
                        }
                    }

                    .--checkbox {
                        display: flex;
                        margin-left: auto;
                        padding: 0 2rem 0 0;
                    }

                    @include hover() {
                        .--title {
                            color: color(base, PrimaryButton);
                        }

                        img {
                            transform: scale(.9);
                        }
                    }
                }
            }

            .more__results {
                display: flex;
                justify-content: center;
                border-top: .1rem solid color(Gray, Gray-200);
                padding: .5rem 0;

                a {
                    @include hover() {
                        text-decoration: underline;
                    }
                }
            }
        }
    }
}
