.dashboard__wrapper {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(1, minmax(0, 1fr));
    @include breakpoint(medium) {
        grid-template-columns: repeat(auto-fit, minmax(1rem, 1fr));
    }
}

.dashboard__block {
    border: .1rem solid #e5e5e5;
    border-radius: .3rem;
    margin: 1.5rem 0;
    padding: 1rem;

    h4 {
        margin-bottom: 1rem;
        font-size: 1.4rem;
    }

    select {
        height: 4rem;
        width: 100%;
    }

    .search__autocomplete {
        .search__autocomplete_dropdown {
            flex-direction: column;
            gap: 0;
            left: 0;

            .--products {
                max-height: 30rem;
                overflow-y: scroll;
            }

            .--actions {
                border-top: .1rem solid color(Gray, Gray-200);
                padding: .5rem;
                display: flex;
                justify-content: center;

                > button {
                    min-width: 20rem;
                    justify-content: center;
                }
            }
        }
    }
}

.dashboard__options-group {
    label {
        display: flex;
        flex-direction: column;
        font-size: 1.6rem;
    }

    &.radio__button-group {
        display: flex;
        align-items: center;
        gap: 2.5rem;

        label {
            cursor: pointer;
            flex-direction: row;
            align-items: center;
            gap: 1rem;
        }
    }
}

.dashboard__options-group {
    display: flex;
    align-items: center;
    gap: 3rem;

    > div {
        flex: 1;

        label {
            font-size: 1.4rem;
            font-weight: 700;
            margin-bottom: 1rem;
        }

        input:not(.vs__search), select {
            width: 100%;
        }
    }

    & + button {
        margin: 1.5rem 0 0;
        font-weight: 600;
        font-size: 1.4rem;
        text-decoration: underline;
    }
}
