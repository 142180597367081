.product_slides {
    margin-top: 48px;
    margin-bottom: 48px;
    padding: 0 48px;
    @include breakpoint(small only) {
        padding-top: 24px;
    }
    .swiper_slider_container {
        margin-left: -48px;
        margin-right: -48px;
        width: auto;
    }
    section {
        position: relative;
    }
    .--prev {
        position: absolute;
        top: 50%;
        left: -24px;
        height: 20px;
        width: 10px;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 15.8 33.05' style='enable-background:new 0 0 15.8 33.05;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D1D1B;%7D%0A%3C/style%3E%3Cpath class='st0' d='M11.13,0l4.18,0c0.46,0,0.66,1.05,0.3,1.57L6.23,15.25c-0.45,0.65-0.45,1.89,0,2.54l9.38,13.68 c0.36,0.52,0.15,1.57-0.3,1.57h-4.18c-0.68,0-1.35-0.42-1.88-1.2L0.79,19.51c-1.05-1.53-1.05-4.43,0-5.97L9.25,1.2 C9.78,0.42,10.44,0,11.13,0z'/%3E%3C/svg%3E%0A");
        background-position: center;
        background-repeat: no-repeat;
        font-size: 0;
        z-index: 2;
    }
    .--next {
        position: absolute;
        top: 50%;
        right: -24px;
        height: 20px;
        width: 10px;
        background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 27.6.1, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 15.8 33.05' style='enable-background:new 0 0 15.8 33.05;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%231D1D1B;%7D%0A%3C/style%3E%3Cpath class='st0' d='M4.67,33.05H0.49c-0.46,0-0.66-1.05-0.3-1.57l9.38-13.68c0.45-0.65,0.45-1.89,0-2.54L0.18,1.57 C-0.18,1.05,0.03,0,0.49,0l4.18,0c0.68,0,1.35,0.42,1.88,1.2l8.46,12.34c1.05,1.53,1.05,4.43,0,5.97L6.55,31.84 C6.02,32.62,5.35,33.05,4.67,33.05z'/%3E%3C/svg%3E%0A");        background-position: center;
        background-repeat: no-repeat;
        font-size: 0;
        z-index: 2;
    }
    .swiper-wrapper {
        padding: 0 70px;
        @include breakpoint(small only) {
            padding: 0 12px;
        }
    }
    .swiper-slide {
        width: auto;
        .image-container {
            img {
                height: 70vh;
                @include breakpoint(small only) {
                    height: 200px;
                }
            }
        }
        .video-container {
            position: relative;
            height: 70vh;
            width: 124vh;
            @include breakpoint(small only) {
                height: 200px;
                width: 355px;
            }
            iframe {
                left: 0;
                object-fit: contain;
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
            }
        }
    }
}
.swiper_slider-pagination {
    margin-top: 48px;
    text-align: center;
    .swiper-pagination-bullet-active {
        background: color(base, DarkGray);
    }
}
