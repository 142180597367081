.store-locator {
    .vue-map-container {
        height: 50rem;
    }

    .--fullscreen > .grid-x {
        border-bottom: .5rem solid color(base, DefaultFontColor);
    }

    .store__information {
        margin: 2rem 0;
        padding: 2rem 5rem 2rem 0;
        border-bottom: .5rem solid color(base, DefaultFontColor);
        display: inline-block;

        .--header {
            position: relative;
            margin: 0 0 .5rem;

            img {
                position: absolute;
                left: -3.2rem;
                height: 4rem;
                top: 1rem;
            }

            h2 {
                font-size: 2.4rem;
            }

            span {
                font-style: italic;
                font-size: 1.6rem;
                position: relative;
                top: -.3rem;
            }
        }

        address {
            p {
                font-size: 1.8rem;
                line-height: 3rem;
            }

            a {
                display: flex;
                font-size: 1.8rem;
                line-height: 3rem;
                align-items: center;
                gap: 1rem;

                svg {
                    width: 1.6rem;
                }
            }
        }
    }

    .no__store {
        display: block;
        font-weight: 600;
        margin: 4rem 0;
        font-size: 1.6rem;
    }

    .store__filter {
        background: color(Gray, Gray-100);
        padding: 2rem;
        margin: 2rem 0;

        @include breakpoint(medium) {
            max-width: 80%;
        }

        ul {
            margin: 1rem 0 0;

            li {
                display: flex;
                gap: 1rem;
                align-items: center;
                padding: .5rem 0;
            }

            label {
                display: flex;
                align-items: center;
                gap: .5rem;
                font-size: 1.6rem;
                cursor: pointer;
            }
        }
    }
}
