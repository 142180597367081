.catalog-category-page {
    @include breakpoint(small only) {
        .breadcrumbs {
            display: none;
        }

        h1 {
            text-align: center;
        }
    }

    .toolbar__container {
        position: relative;
        margin: 0 0 3rem;

        @include breakpoint(small only) {
            border-bottom: .2rem solid color(base, DefaultFontColor);
            margin: 0;
            padding: 0 0 1rem;
        }

        .catalog-category__toolbar {
            &:not(.--placeholder) {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
            }

            .product__count-mobile {
                display: block;
                text-align: center;
                line-height: 4rem;
                color: color(gray, Gray-300);
            }

            .toolbar__top {
                display: flex;
                justify-content: space-between;
                align-items: center;

                > span, > div {
                    flex: 0 0 50%;

                    &:is(span) {
                        display: block;
                        font-size: 1.6rem;
                    }

                    &:is(div) {
                        display: flex;
                        justify-content: flex-end;
                        align-items: center;

                        label {
                            font-size: 1.6rem;
                            color: color(gray, Gray-400);
                        }
                    }
                }

                .mobile-filter {
                    button {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='#{color(base, White)}' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z' /%3E%3C/svg%3E%0A");
                        background-repeat: no-repeat;
                        background-position: right 1rem center;
                        background-size: 1.4rem;
                        padding-right: 3.4rem;
                    }
                }

                .--actions {
                    display: flex;
                    gap: 2rem;

                    select {
                        min-width: auto;
                    }
                }
            }
        }
    }
}
