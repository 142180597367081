.off-canvas-module {
    section {
        height: 100%;
        display: flex;
        flex-direction: column;

        .modal__header {
            border-color: color(Gray, Gray-200);
            font-weight: 700;
        }

        .modal__content {
            flex: 1;

            &.--center {
                display: flex;
                flex-flow: column;
                justify-content: center;
            }
        }
    }
}

.off-canvas.--menu {
    background-color: color(base, White);

    .modal__header {
        background-color: color(base, White);
        border-bottom: none;
        height: 4.6rem;
        border-bottom: .1rem solid color(Gray, Gray-200);

        > span {
            text-transform: uppercase;
            font-weight: 700;
        }
    }

    .menu {
        margin: 1rem 0 0;

        .js-drilldown-back {
            display: none;
        }

        .--dropdown {
            display: none;

            .grid-container {
                padding: 0;

                .invisible {
                    visibility: visible;
                }

                .column > span {
                    display: none;
                }

                ul {
                    margin-left: 1rem;

                    li {
                        a, span {
                            font-size: 1.4rem;
                            line-height: 5rem;
                            border: none;
                            font-weight: normal;
                            text-transform: none;
                        }
                    }
                }
            }
        }

        > ul > li {
            a, span {
                color: color(base, DefaultFontColor);
                padding: 0 1rem;
                line-height: 5rem;
                text-transform: uppercase;
                font-size: 1.4rem;
                border-top: .1rem solid color(Gray, Gray-200);
                border-bottom: none;
                display: block;
                font-weight: 400;
                background-repeat: no-repeat;
                background-position: right 1rem center;
                background-size: 1.6rem;
            }

            &.is-drilldown-submenu-parent {
                > a, > span {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M12 4.5v15m7.5-7.5h-15' /%3E%3C/svg%3E%0A");
                }
            }

            &.--active {
                > a, > span {
                    font-weight: 700;
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 24 24' stroke-width='3' stroke='currentColor' class='w-6 h-6'%3E%3Cpath stroke-linecap='round' stroke-linejoin='round' d='M5 12h14' /%3E%3C/svg%3E%0A");
                }

                .--dropdown {
                    display: block;
                }
            }

            .spacer > a, .spacer > span {
                display: none;
            }

            .navigation__image {
                margin: 3rem 0;
                padding-right: 1rem;
            }
        }
    }
}

.top__menu {
    .language__dropdown .language__dropdown {
        display: none;

        &.--active {
            display: block;
        }
    }
}

.customer__help__content {
    .off-canvas {
        width: 40rem;

        &.position-left:not(.is-open) {
            transform: translateX(-40rem);
        }

        .modal__content {
            padding: 1rem;

            .global__content > *:not(:last-child) {
                margin-bottom: 1rem;
            }
        }
    }
}
